<template>
  <div class="flex items-center">
    <dropdown-select v-model="horizontalAttribute" class="mr-4" @change="setPositionAfterChange">
      <option value="right">Right</option>
      <option value="left">Left</option>
    </dropdown-select>
    <InputWithUnit v-if="horizontal" v-model="horizontal" :alternative-units="['px']" size="small" @updated="setPositionAfterChange" />
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "HorizontalPosition",
  components: { DropdownSelect, InputWithUnit },
  mixins: [appearanceChangerMixin],
  props: ["leftVariable", "rightVariable"],
  data() {
    return {
      horizontalAttribute: "right",
      horizontal: null
    }
  },
  methods: {
    setPositionAfterChange() {
      if (this.horizontalAttribute === "right") {
        this.set(this.leftVariable, "unset")
        this.set(this.rightVariable, this.horizontal.value + this.horizontal.unit)
      } else {
        this.set(this.rightVariable, "unset")
        this.set(this.leftVariable, this.horizontal.value + this.horizontal.unit)
      }
    },
    set(variable, value) {
      this.setStyleVariableDirect({
        env: this.env,
        variable: variable,
        value
      })
    }
  },
  created() {
    const leftValue = this.getStyleVariableValue(this.env, this.leftVariable, 1)
    const rightValue = this.getStyleVariableValue(this.env, this.rightVariable, 1)

    if (leftValue.value === "unset") {
      this.horizontalAttribute = "right"
      this.horizontal = rightValue
    } else {
      this.horizontalAttribute = "left"
      this.horizontal = leftValue
    }
  }
}
</script>
