const axios = require("axios")
const register = async ({ name, email, password, password_confirmation }) => {
  return await axios.post("auth/create-account", {
    name,
    email,
    password,
    password_confirmation
  })
}

const login = async ({ email, password }) => {
  return await axios.post("auth/access-token", {
    email,
    password
  })
}

const fetchAddressAndTaxId = async () => await window.axios.get("user/customer-address")

const fetchAvailablePlans = async () => await window.axios.get("plans")

const fetchCurrentUser = async () => await window.axios.get("user")

const saveAddress = async ({ address, taxId }) => {
  return await window.axios.post("user/customer-address", {
    address,
    tax_id: taxId
  })
}

const updateUserPlan = async ({ planId }) => {
  return await window.axios.put("user/subscription", {
    plan: planId
  })
}

// stripe
const fetchStripePublicApiKey = async () => await window.axios.get("stripe-key")
const createAndFetchStripeSetupIntent = async () => await window.axios.get("user/setup-intent")
const addPaymentMethod = async (paymentMethod) => await window.axios.post("user/payment-methods", { payment_method: paymentMethod })
const fetchPaymentMethods = async () => await window.axios.get("user/payment-methods")
const deletePaymentMethod = async (paymentMethodId) => await window.axios.delete(`user/payment-methods/${paymentMethodId}`)
const fetchInvoices = async () => await window.axios.get("user/invoices")
const fetchSites = async () => await window.axios.get("sites")

const userAPI = {
  login,
  register,
  fetchAddressAndTaxId,
  saveAddress,
  fetchAvailablePlans,
  fetchCurrentUser,
  updateUserPlan,
  fetchStripePublicApiKey,
  addPaymentMethod,
  createAndFetchStripeSetupIntent,
  fetchPaymentMethods,
  deletePaymentMethod,
  fetchInvoices,
  fetchSites
}
export default userAPI
