import Login from "@/views/Auth/Login"
import Register from "@/views/Auth/Register"

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  }
]
export default authRoutes
