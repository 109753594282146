<template>
  <div class="px-8 py-6 bg-white shadow-lg rounded-lg">
    <heading>Invoices</heading>
    <div v-if="loading">Loading</div>
    <table v-else class="rounded-lg table-fixed w-full mt-4">
      <thead>
        <tr>
          <th class="bg-gray-900 text-white font-medium text-left px-4 py-2">Period Start</th>
          <th class="bg-gray-900 text-white font-medium text-left px-4 py-2">Period End</th>
          <th class="bg-gray-900 text-white font-medium text-left px-4 py-2">Invoice PDF</th>
          <th class="bg-gray-900 text-white font-medium text-left px-4 py-2">Hosted Invoice</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(invoice, key) in invoices" :key="key" class="text-gray-700">
          <td class="border border-gray-200 px-4 py-2">{{ invoice["period_start"] }}</td>
          <td class="border border-gray-200 px-4 py-2">{{ invoice["period_end"] }}</td>
          <td class="border border-gray-200 px-4 py-2"><a :href="invoice['invoice_pdf']">Click</a></td>
          <td class="border border-gray-200 px-4 py-2"><a :href="invoice['hosted_invoice_url']">Click</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Heading from "@/components/dashboard/Title"
import { mapActions } from "vuex"

export default {
  name: "Invoices",
  components: { Heading },
  data() {
    return {
      loading: true,
      invoices: []
    }
  },
  methods: mapActions("user", ["fetchInvoices"]),
  mounted() {
    this.loading = true
    this.fetchInvoices().then((inv) => (this.invoices = inv))
    this.loading = false
  }
}
</script>
