<template>
  <SettingsElement>
    <template #title>{{ title }}</template>
    <template #body>
      <HorizontalPosition left-variable="--close-button-left" right-variable="--close-button-right" />
    </template>
  </SettingsElement>
</template>
<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import HorizontalPosition from "@/components/campaign-builder/appearance-changers/HorizontalPosition"

export default {
  name: "SettingsHorizontalPosition",
  components: { HorizontalPosition, SettingsElement },
  props: {
    leftVariable: {
      type: String,
      required: true
    },
    rightVariable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Position"
    }
  }
}
</script>
