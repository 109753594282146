<template>
  <input-with-unit v-model="fontSize" :alternative-units="['px']" size="small" @updated="set" />
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "FontSize",
  components: { InputWithUnit },
  data() {
    return {
      fontSize: {}
    }
  },
  mixins: [appearanceChangerMixin],
  methods: {
    set() {
      this.setStyleVariableDirect({
        env: this.env,
        variable: this.variable,
        value: this.fontSize.value + this.fontSize.unit
      })
    }
  },
  created() {
    const styleVariable = this.getStyleVariableValue(this.env, this.variable, 1)
    this.fontSize.value = styleVariable.value
    this.fontSize.unit = styleVariable.unit
  }
}
</script>
