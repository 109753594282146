<template>
  <dashboard-layout>
    <div class="px-8 py-6 bg-white rounded-lg shadow-lg">
      <heading>Campaigns</heading>
      <!--      <router-link :to="{ name: 'CreateCampaign' }" class="px-4 py-2 rounded-md font-medium text-white bg-indigo-700">Create</router-link>-->
      <table class="text-gray-800 table-fixed mt-8 w-full">
        <thead>
          <tr>
            <th class="bg-gray-700 text-white font-medium py-2 px-4">Domain</th>
            <th class="bg-gray-700 text-white font-medium py-2 px-4">Campaign ID</th>
            <th class="bg-gray-700 text-white font-medium py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="campaign in getCampaigns" :key="campaign.id">
            <td class="px-4 py-4 border border-gray-200">{{ campaign.domain }}</td>
            <td class="px-4 py-4 border border-gray-200">{{ campaign.id }}</td>
            <td class="px-4 py-4 border border-gray-200">
              <router-link :to="{ name: 'CreateCampaign' }" class="px-4 py-2 rounded-md font-medium text-white bg-indigo-700"
                >Edit Campaign
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layout/DashboardLayout"
import { mapActions, mapGetters } from "vuex"
import Heading from "@/components/dashboard/Title"

export default {
  name: "Campaigns",
  components: { Heading, DashboardLayout },
  computed: mapGetters("user", ["getCampaigns"]),
  methods: {
    ...mapActions("user", ["fetchSites"])
  },
  created() {
    this.fetchSites()
  }
}
</script>

<style scoped></style>
