<template>
  <span ref="color-picker" class="color-picker"></span>
</template>

<script>
import Pickr from "@simonwep/pickr"
import "@simonwep/pickr/dist/themes/nano.min.css"

export default {
  name: "ColorPicker",
  data() {
    return {
      pickr: null
    }
  },
  props: {
    value: { required: true },
    showOpacity: { default: true }
  },
  beforeDestroy() {
    this.pickr.destroyAndRemove()
  },
  mounted() {
    let interaction = {}
    if (this.showOpacity) {
      interaction = {
        hex: true,
        input: true,
        rgba: true
      }
    } else {
      interaction = {
        hex: true,
        input: false,
        rgba: false
      }
    }
    this.pickr = Pickr.create({
      el: this.$refs["color-picker"],
      theme: "nano",
      default: this.value,
      closeOnScroll: true,
      comparison: false,
      position: "bottom-start",
      autoReposition: false,
      components: {
        preview: true,
        opacity: this.showOpacity,
        hue: true,
        interaction
      }
    }).on("change", (color) => {
      this.$emit("change", color.toRGBA().toString())
    })
  }
}
</script>
<style>
.pickr button {
  position: unset;
  box-sizing: border-box;
  border: 2px solid #999 !important;
}

.pickr {
  display: inline-block;
  margin: 0;
}
</style>
