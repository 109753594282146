<template>
  <div>
    <div class="mb-4 flex items-center">
      <label class="w-20 inline-block" for="pbborderposition">Position</label>
      <dropdown-select id="pbborderposition" v-model="currentValues.position">
        <option value="1 1 1 1">All</option>
        <option value="1 0 1 0">Top - Bottom</option>
        <option value="0 1 0 1">Left - Right</option>
        <option value="1 0 0 0">Top</option>
        <option value="0 1 0 0">Right</option>
        <option value="0 0 1 0">Bottom</option>
        <option value="0 0 0 1">Left</option>
        <option value="0 0 0 0">None</option>
      </dropdown-select>
    </div>
    <div class="mb-4 flex items-center">
      <label class="w-20 inline-block" for="pbborderstyle">Style</label>
      <dropdown-select id="pbborderstyle" v-model="currentValues.style">
        <option v-for="style in styleOptions" :key="style" :selected="currentValues.style === style" :value="style">
          {{ style.charAt(0).toUpperCase() + style.slice(1, style.length) }}
        </option>
      </dropdown-select>
    </div>
    <div class="mb-4 flex items-center">
      <label class="w-20 inline-block" for="pbborderwidth">Width</label>
      <input id="pbborderwidth" v-model="currentValues.width" max="25" min="0" type="range" />
    </div>
    <div class="flex items-center">
      <label class="w-20 inline-block" for="pbbordercolor">Color</label>
      <Color id="pbbordercolor" :variable="colorVariable" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Color from "@/components/campaign-builder/appearance-changers/Color"
import DropdownSelect from "@/components/common/DropdownSelect"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "Border",
  components: { DropdownSelect, Color },
  props: ["widthVariable", "colorVariable", "styleVariable"],
  mixins: [appearanceChangerMixin],
  computed: {
    ...mapGetters("preview", ["getStyleVariableValue"])
  },
  data() {
    return {
      styleOptions: ["dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset", "none"],
      currentValues: {
        style: null,
        width: null,
        color: null,
        position: null
      }
    }
  },
  watch: {
    currentValues: {
      deep: true,
      handler: function () {
        this.setStyleVariableValue({
          env: this.env,
          variable: this.styleVariable,
          value: this.currentValues.style
        })
        const self = this
        const w = this.currentValues.position
          .split(" ")
          .map(function (p) {
            return parseInt(p) * parseInt(self.currentValues.width) + "px"
          })
          .join(" ")
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.widthVariable,
          value: w
        })
      }
    }
  },
  mounted() {
    this.currentValues.style = this.getStyleVariableValue(this.env, this.styleVariable, 0)
    this.currentValues.width = this.getStyleVariableValue(this.env, this.widthVariable, 0).replaceAll("px", "").split(" ")[0]

    let positionMatrix = ["0", "0", "0", "0"]
    this.getStyleVariableValue(this.env, this.widthVariable, 0)
      .replaceAll("px", "")
      .split(" ")
      .forEach((w, k) => {
        if (parseInt(w) > 0) {
          positionMatrix[k] = "1"
        }
      })
    this.currentValues.position = positionMatrix.join(" ")
  }
}
</script>
