<template>
  <div class="relative inline-block w-auto text-gray-800">
    <select
      ref="select"
      :value="value"
      class="py-1 pl-3 pr-6 w-full text-base outline-none placeholder-gray-600 border rounded-lg appearance-none"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @input="$emit('input', $event.target.value)"
      @keydown="$emit('keydown', $event)"
      @keyup="$emit('keyup', $event)"
    >
      <slot />
    </select>
    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
      <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
        <path
          clip-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          fill-rule="evenodd"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownSelect",
  props: ["value"],
  mounted() {
    if (!this.value) {
      this.$refs.select.value = this.$refs.select.firstElementChild.value
    }
  }
}
</script>
