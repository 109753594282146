const generateAPIErrorMessage = function (axiosErr) {
  let errorMessage = ""
  if (axiosErr.response.data.message) {
    errorMessage += axiosErr.response.data.message + "\n"
  }
  if (axiosErr.response.data.errors) {
    Object.values(axiosErr.response.data.errors).forEach((errInfo) => {
      Object.values(errInfo).forEach((errText) => {
        errorMessage += errText + "\n"
      })
    })
  }
  if (axiosErr.response.data.data.error && axiosErr.response.data.data.error.message) {
    errorMessage += axiosErr.response.data.data.error.message
  }
  if (errorMessage === "") {
    errorMessage = "An error happened."
  }
  return errorMessage.trim()
}
export default generateAPIErrorMessage
