<template>
  <div :class="size" class="text-gray-700 input-with-unit rounded-md border border-gray-200 flex items-center overflow-hidden">
    <input ref="value" :value="value.value" class="text-base px-2 py-1 border-r border-gray-200" type="number" @input="input" />

    <div class="relative inline-block w-auto text-gray-800">
      <select
        ref="unit"
        v-model="value.unit"
        class="py-1 pr-3 pl-1 text-sm outline-none placeholder-gray-600 appearance-none"
        @input="input"
      >
        <option v-for="u in alternativeUnits" :key="u" :value="u">
          {{ u.toUpperCase() }}
        </option>
      </select>
      <div class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
        <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path
            clip-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputWithUnit",
  props: {
    value: {
      required: true,
      type: Object
    },
    alternativeUnits: {
      required: true,
      type: Array
    },
    size: {
      default: "normal"
    }
  },
  methods: {
    input() {
      this.$emit("input", {
        value: this.$refs.value.value,
        unit: this.$refs.unit.value
      })
      this.$emit("updated")
    }
  }
}
</script>

<style lang="scss" scoped>
.input-with-unit {
  width: min-content;

  input {
    -webkit-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.full {
    @apply w-full;
    input {
      @apply w-full;
    }
  }

  &.small {
    width: 6.5rem;
    min-width: 6.5rem;
    max-width: 6.5rem;

    input {
      width: 3.5rem;
      min-width: 3.5rem;
    }

    select {
      width: 3rem;
      min-width: 3rem;
    }
  }

  &.normal {
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;

    input {
      width: 4.5rem;
      min-width: 4.5rem;
    }

    select {
      width: 3.5rem;
      min-width: 3.5rem;
    }
  }
}
</style>
