<template>
  <color-picker v-if="initialValue" :show-opacity="!this.unFormatted" :value="initialValue" @change="setColor" />
</template>

<script>
import ColorPicker from "@/components/campaign-builder/appearance-changers/base/ColorPicker"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "Color",
  mixins: [appearanceChangerMixin],
  components: { ColorPicker },
  props: {
    unFormatted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { initialValue: null }
  },
  methods: {
    setColor(c) {
      if (this.unFormatted) {
        c = c.replace("rgba(", "").replace(", 1)", "")
      }
      this.setStyleVariableValue({
        env: this.env,
        value: c,
        variable: this.variable
      })
    }
  },
  mounted() {
    let c = this.getStyleVariableValue(this.env, this.variable, 0)
    if (this.unFormatted) {
      c = `rgba(${c}, 1)`
    }
    this.initialValue = c
  }
}
</script>
