<template>
  <div class="w-full flex items-center mb-4">
    <label class="w-48">
      <slot name="label" />
    </label>
    <div class="flex-1">
      <slot name="input" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFormField"
}
</script>
