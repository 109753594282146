function modalStyleVariables() {
  const formDefault = {
    "--form-margin": "0px 0px 16px 0px",
    "--form-padding": "0px 0px 0px 0px",
    "--form-primary-button-background": "#333333",
    "--form-primary-button-border-radius": "8px 8px 8px 8px",
    "--form-primary-button-padding": "11px 23px 11px 23px",
    "--form-primary-button-margin": "6px 0px 6px 0px",
    "--form-primary-button-font-size": "15px",
    "--form-primary-button-color": "#ffffff",
    "--form-primary-button-border-style": "none",
    "--form-primary-button-border-width": "0px 0px 0px 0px",
    "--form-primary-button-border-color": "#f00",
    "--form-secondary-button-background": "#888",
    "--form-secondary-button-border-radius": "8px 8px 8px 8px",
    "--form-secondary-button-padding": "11px 23px 11px 23px",
    "--form-secondary-button-margin": "6px 0px 6px 0px",
    "--form-secondary-button-font-size": "15px",
    "--form-secondary-button-color": "#ffffff",
    "--form-secondary-button-border-style": "none",
    "--form-secondary-button-border-width": "0px 0px 0px 0px",
    "--form-secondary-button-border-color": "#f00",
    "--form-field-margin": "20px 0px 0px 0px",
    "--form-field-padding": "0px 0px 0px 0px",
    "--form-field-color": "30, 30, 30",
    "--form-field-font-size": "15px",
    "--form-label-font-size": "14px",
    "--form-label-color": "#333333",
    "--form-label-margin": "0px 0px 8px 0px"
  }
  const closeButtonDefault = {
    "--close-button-width": "24px",
    "--close-button-height": "24px",
    "--close-button-padding": "4px 4px 4px 4px",
    "--close-button-border-radius": "0px 0px 0px 0px",
    "--close-button-top": "10px",
    "--close-button-right": "10px",
    "--close-button-bottom": "unset",
    "--close-button-left": "unset",
    "--close-button-display": "block",
    "--close-button-background": "transparent",
    "--close-button-color": "#2b2b2b"
  }
  return {
    panelImage: {
      style: {
        desktop: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "360px",
          "--layout-max-height": "95vh",
          "--layout-margin": "0px 24px 0px 24px",
          "--layout-padding": "300px 0px 0px 0px",
          "--layout-border-radius": "12px 12px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "220px",
          "--image-top": "60px",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          "--expand-button-width": "28px",
          "--expand-button-height": "28px",
          "--expand-button-padding": "16px 8px 16px 8px",
          "--expand-button-border-radius": "0px 0px 0px 0px",
          "--expand-button-top": "0px",
          "--expand-button-right": "0px",
          "--expand-button-bottom": "unset",
          "--expand-button-left": "unset",
          "--expand-button-display": "block",
          "--expand-button-background": "transparent",
          "--expand-button-color": "#2b2b2b",
          "--body-padding": "0px 30px 20px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--title-font-size": "20px",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "24px 0px 0px 0px"
        },
        mobile: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "0vh 5vw 0vh 5vw",
          "--layout-padding": "240px 0px 0px 0px",
          "--layout-border-radius": "12px 12px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "60px",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          "--expand-button-width": "28px",
          "--expand-button-height": "28px",
          "--expand-button-padding": "16px 8px 16px 8px",
          "--expand-button-border-radius": "0px 0px 0px 0px",
          "--expand-button-top": "0px",
          "--expand-button-right": "0px",
          "--expand-button-bottom": "unset",
          "--expand-button-left": "unset",
          "--expand-button-display": "block",
          "--expand-button-background": "transparent",
          "--expand-button-color": "#2b2b2b",
          "--body-padding": "0px 30px 20px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 200px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    panelNoImage: {
      style: {
        desktop: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "360px",
          "--layout-max-height": "95vh",
          "--layout-margin": "0px 24px 0px 24px",
          "--layout-padding": "80px 0px 0px 0px",
          "--layout-border-radius": "12px 12px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--expand-button-width": "28px",
          "--expand-button-height": "28px",
          "--expand-button-padding": "16px 8px 16px 8px",
          "--expand-button-border-radius": "0px 0px 0px 0px",
          "--expand-button-top": "0px",
          "--expand-button-right": "0px",
          "--expand-button-bottom": "unset",
          "--expand-button-left": "unset",
          "--expand-button-display": "block",
          "--expand-button-background": "transparent",
          "--expand-button-color": "#2b2b2b",
          "--body-padding": "0px 30px 20px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--title-font-size": "20px",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "24px 0px 0px 0px"
        },
        mobile: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "0vh 5vw 0vh 5vw",
          "--layout-padding": "80px 0px 0px 0px",
          "--layout-border-radius": "12px 12px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--expand-button-width": "28px",
          "--expand-button-height": "28px",
          "--expand-button-padding": "16px 8px 16px 8px",
          "--expand-button-border-radius": "0px 0px 0px 0px",
          "--expand-button-top": "0px",
          "--expand-button-right": "0px",
          "--expand-button-bottom": "unset",
          "--expand-button-left": "unset",
          "--expand-button-display": "block",
          "--expand-button-background": "transparent",
          "--expand-button-color": "#2b2b2b",
          "--body-padding": "0px 30px 20px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 200px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    doubleColumnRightImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "568px",
          "--layout-max-height": "95vh",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 264px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "264px",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "unset",
          "--image-left": "unset",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.4)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "94vw",
          "--layout-max-height": "96vh",
          "--layout-margin": "2vh 3vw 2vh 3vw",
          "--layout-padding": "160px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    doubleColumnLeftImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "568px",
          "--layout-max-height": "95vh",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 0px 264px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "264px",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "94vw",
          "--layout-max-height": "96vh",
          "--layout-margin": "2vh 3vw 2vh 3vw",
          "--layout-padding": "160px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0px 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    singleColumnTopImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "360px",
          "--layout-max-height": "95vh",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "220px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "220px",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "5vh 5vw 5vh 5vw",
          "--layout-padding": "160px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    singleColumnBottomImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "360px",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 220px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-max-height": "95vh",
          "--image-width": "100%",
          "--image-height": "220px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "5vh 5vw 5vh 5vw",
          "--layout-padding": "0px 0px 160px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    singleColumnNoImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "360px",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-max-height": "95vh",
          "--image-width": "100%",
          "--image-height": "220px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "5vh 5vw 5vh 5vw",
          "--layout-padding": "0px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "180px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    backgroundImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "576px",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "30px 190px 30px 30px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-max-height": "95vh",
          "--image-width": "100%",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "20px 20px 20px 20px",
          "--body-background": "#ffffff",
          "--body-border-radius": "16px 16px 16px 16px",
          "--body-shadow": "0 10px 15px rgba(30, 40, 50, 0.3)",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "90vw",
          "--layout-max-height": "90vh",
          "--layout-margin": "5vh 5vw 5vh 5vw",
          "--layout-padding": "32px 32px 32px 32px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "100%",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "20px 20px 20px 20px",
          "--body-background": "#ffffff",
          "--body-border-radius": "16px 16px 16px 16px",
          "--body-shadow": "0 10px 15px rgba(30, 40, 50, 0.3)",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenBackgroundImage: {
      style: {
        desktop: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "10% 50% 10% 10%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-height": "100vh",
          "--layout-max-height": "100vh",
          "--image-width": "100%",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "#ffffff",
          "--body-border-radius": "16px 16px 16px 16px",
          "--body-shadow": "0 10px 15px rgba(30, 40, 50, 0.3)",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "10% 10% 10% 10%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-height": "100vh",
          "--layout-max-height": "100vh",
          "--image-width": "100%",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 50px 50px 50px",
          "--body-background": "#ffffff",
          "--body-border-radius": "16px 16px 16px 16px",
          "--body-shadow": "0 10px 15px rgba(30, 40, 50, 0.3)",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenDoubleColumnLeftImage: {
      style: {
        desktop: {
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "30px 5% 30px 50%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "flex",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "center",
          "--layout-justify-content": "unset",
          "--layout-shadow": "0",
          "--image-width": "50%",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "250px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0",
          "--image-width": "100%",
          "--image-height": "250px",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "unset",
          "--image-left": "unset",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenDoubleColumnRightImage: {
      style: {
        desktop: {
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "30px 50% 30px 5%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "flex",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "center",
          "--layout-justify-content": "unset",
          "--layout-shadow": "0",
          "--image-width": "50%",
          "--image-height": "100%",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "unset",
          "--image-left": "unset",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "250px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0",
          "--image-width": "100%",
          "--image-height": "250px",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "unset",
          "--image-left": "unset",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenSingleColumnNoImage: {
      style: {
        desktop: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-height": "100vh",
          "--layout-max-height": "100vh",
          "--image-width": "100%",
          "--image-height": "220px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "50px 30% 50px 30%",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100vh",
          "--layout-max-height": "100vh",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "160px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenSingleColumnTopImage: {
      style: {
        desktop: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "360px 30% 0 30%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0",
          "--image-width": "40%",
          "--image-height": "360px",
          "--image-top": "0",
          "--image-right": "unset",
          "--image-bottom": "unset",
          "--image-left": "30%",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-height": "100%",
          "--layout-max-height": "100%",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "250px 0px 0px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0",
          "--image-width": "100%",
          "--image-height": "250px",
          "--image-top": "0",
          "--image-right": "0",
          "--image-bottom": "unset",
          "--image-left": "unset",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    },
    fullScreenSingleColumnBottomImage: {
      style: {
        desktop: {
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 30% 240px 30%",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--layout-max-height": "100vh",
          "--layout-height": "100vh",
          "--image-width": "40%",
          "--image-height": "240px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "30%",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "30px 30px 30px 30px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 16px 0px",
          "--title-font-size": "24px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "16px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "16px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        },
        mobile: {
          "--overlay-backdrop-blur": "0px",
          "--overlay-color": "rgba(0,0,0,.2)",
          "--overlay-display": "block",
          "--layout-font-family": "Poppins",
          "--layout-overflow": "auto",
          "--layout-width": "100vw",
          "--layout-max-height": "100vh",
          "--layout-height": "100vh",
          "--layout-margin": "0px 0px 0px 0px",
          "--layout-padding": "0px 0px 200px 0px",
          "--layout-border-radius": "0px 0px 0px 0px",
          "--layout-background": "#ffffff",
          "--layout-display": "block",
          "--layout-flex-direction": "unset",
          "--layout-align-items": "unset",
          "--layout-justify-content": "unset",
          "--layout-border-style": "none",
          "--layout-border-width": "0px 0px 0px 0px",
          "--layout-border-color": "#f00",
          "--layout-shadow": "0 10px 15px rgba(40, 50, 60, 0.25)",
          "--image-width": "100%",
          "--image-height": "200px",
          "--image-top": "unset",
          "--image-right": "unset",
          "--image-bottom": "0",
          "--image-left": "0",
          "--image-object-fit": "cover",
          "--image-opacity": "1",
          "--image-transform": "none",
          ...closeButtonDefault,
          "--body-padding": "40px 40px 40px 40px",
          "--body-background": "transparent",
          "--body-border-radius": "0px 0px 0px 0px",
          "--body-shadow": "0",
          "--title-margin": "0px 0px 12px 0px",
          "--title-font-size": "18px",
          "--title-color": "#2b2b2b",
          "--title-background": "transparent",
          "--description-margin": "0px 0px 16px 0px",
          "--description-font-size": "15px",
          "--description-color": "#666",
          "--description-background": "transparent",
          "--subtitle-margin": "0px 0px 16px 0px",
          "--subtitle-font-size": "15px",
          "--subtitle-color": "#666",
          "--subtitle-background": "transparent",
          ...formDefault,
          "--notes-font-size": "14px",
          "--notes-color": "rgba(40, 45, 50, 0.5)",
          "--notes-background": "transparent",
          "--notes-margin": "32px 0px 0px 0px"
        }
      }
    }
  }
}

export default modalStyleVariables
