import { render, staticRenderFns } from "./SettingsHorizontalPosition.vue?vue&type=template&id=e1d078ac&"
import script from "./SettingsHorizontalPosition.vue?vue&type=script&lang=js&"
export * from "./SettingsHorizontalPosition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports