<template>
  <div class="background-color">
    <div class="w-auto mb-4">
      <button :class="{ active: type === 'transparent' }" class="mr-4" @click="setType('transparent')">Transparent</button>
      <button :class="{ active: type === 'solid' }" class="mr-4" @click="setType('solid')">Solid</button>
      <button :class="{ active: type === 'linear-gradient' }" @click="setType('linear-gradient')">Gradient</button>
    </div>
    <div :key="type">
      <div v-if="type === 'solid'">
        <div class="flex items-center">
          <span class="mr-4">Color</span>
          <Color :env="env" :variable="variable" />
        </div>
      </div>
      <div v-if="type === 'linear-gradient'">
        <color-gradient :env="env" :variable="variable" />
      </div>
    </div>
  </div>
</template>
<script>
import Color from "@/components/campaign-builder/appearance-changers/Color"
import ColorGradient from "@/components/campaign-builder/appearance-changers/ColorGradient"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "BackgroundColor",
  components: { ColorGradient, Color },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      type: ""
    }
  },
  methods: {
    setType(type) {
      this.type = type
      let currentColor = this.getStyleVariableValue(this.env, this.variable, 0)
      if (currentColor.includes("linear-gradient")) {
        currentColor = currentColor.substring(currentColor.indexOf("(") + 1, currentColor.lastIndexOf(")"))
        currentColor = currentColor.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)[1].trim()
      }
      if (type === "transparent") {
        this.setColor("transparent")
      } else if (type === "linear-gradient") {
        this.setColor(`linear-gradient(90deg, ${currentColor}, ${currentColor})`)
      } else if (type === "solid") {
        this.setColor(currentColor)
      }
    },
    setColor(c) {
      this.setStyleVariableDirect({
        env: this.env,
        value: c,
        variable: this.variable
      })
    }
  },
  mounted() {
    const data = this.getStyleVariableValue(this.env, this.variable, 0).trim()
    if (data === "transparent") {
      this.type = "transparent"
    } else if (data.includes("linear-gradient")) {
      this.type = "linear-gradient"
    } else {
      this.type = "solid"
    }
  }
}
</script>

<style lang="scss" scoped>
.background-color {
  button {
    @apply text-gray-800 rounded-md shadow-sm bg-gray-200 text-gray-800 px-2 py-1;
    &.active {
      @apply bg-blue-500;
      @apply text-white;
    }
  }
}
</style>
