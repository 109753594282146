<template>
  <settings-element>
    <template #title>{{ title }}</template>
    <template #body>
      <Color :un-formatted="unFormatted" :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Color from "@/components/campaign-builder/appearance-changers/Color"

export default {
  name: "SettingsColor",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "Color"
    },
    variable: {
      type: String,
      required: true
    },
    unFormatted: {
      type: Boolean,
      default: false
    }
  },
  components: { Color, SettingsElement }
}
</script>
