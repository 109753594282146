<template>
  <div class="w-20">
    <text-input v-model="value" type="number" @input="set" />
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import TextInput from "@/components/common/TextInput"

export default {
  name: "BackdropBlur",
  components: { TextInput },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      value: ""
    }
  },
  methods: {
    set() {
      this.setStyleVariableValue({
        env: this.env,
        variable: this.variable,
        value: this.value
      })
    }
  },
  mounted() {
    const { value } = this.getStyleVariableValue(this.env, this.variable, 1)
    this.value = value
  }
}
</script>
