<template>
  <div>
    <campaign-type-selector @next="createFromZero" />
    <div class="text-center mt-8 py-8 border-t-2 border-gray-200">
      <textarea v-model="html" class="w-192 h-96 border-2 border-gray-200 rounded-lg shadow-lg">
        html
      </textarea>
      <div class="text-center">
        <button class="mt-4 bg-indigo-600 px-6 py-2 text-white font-medium rounded-lg" @click="createFromHTML">Create</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import CampaignTypeSelector from "@/components/campaign-type-select/CampaignTypeSelector"

export default {
  name: "CreateCampaign",
  components: { CampaignTypeSelector },
  data() {
    return {
      html: ""
    }
  },
  methods: {
    ...mapMutations("preview", ["setZeroConfig", "setHtmlConfig", "setInitFromZero", "setInitFromHtml"]),
    createFromZero(data) {
      this.setInitFromZero(true)
      this.setInitFromHtml(false)
      this.setZeroConfig(data)
      this.$router.push({ name: "EditCampaign" })
    },
    createFromHTML() {
      this.setInitFromZero(false)
      this.setInitFromHtml(true)
      this.setHtmlConfig(this.html)
      this.$router.push({ name: "EditCampaign" })
    }
  }
}
</script>
