<template>
  <button
    :class="active ? 'active' : ''"
    class="campaign-type-select-button text-white rounded-lg px-4 py-2"
    @click="(e) => $emit('click', e)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "CampaignTypeSelectButton",
  props: {
    active: {
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-type-select-button {
  @apply bg-pink-700;

  &:hover {
    @apply bg-pink-800;
  }

  &.active {
    @apply bg-pink-900;
  }
}
</style>
