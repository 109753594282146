<template>
  <div>
    <InputWithUnit v-if="firstVariable" v-model="firstVariable" :alternative-units="[firstVariable.unit]" size="small" @updated="update" />
  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "Size",
  components: { InputWithUnit },
  mixins: [appearanceChangerMixin],
  props: ["variables"],
  data() {
    return {
      firstVariable: null
    }
  },
  methods: {
    update() {
      this.variables.forEach((variable) => {
        this.setStyleVariableDirect({
          env: this.env,
          variable: variable,
          value: this.firstVariable.value + this.firstVariable.unit
        })
      })
    }
  },
  created() {
    this.firstVariable = this.getStyleVariableValue(this.env, this.variables[0], 1)
  }
}
</script>
