<template>
  <header class="w-full h-16 bg-indigo-900">Header area</header>
</template>

<script>
export default {
  name: "HeaderNav"
}
</script>

<style scoped></style>
