<template>
  <settings-element>
    <template #title>{{ title }}</template>
    <template #body>
      <Width :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Width from "@/components/campaign-builder/appearance-changers/Width"

export default {
  name: "SettingsWidth",
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Width"
    }
  },
  components: { Width, SettingsElement }
}
</script>
