<template>
  <SettingsElement>
    <template #title>{{ title }}</template>
    <template #body>
      <TwoDirectionPosition :variable="variable" />
    </template>
  </SettingsElement>
</template>
<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import TwoDirectionPosition from "@/components/campaign-builder/appearance-changers/TwoDirectionPosition"

export default {
  name: "SettingsTwoDirectionPosition",
  components: { TwoDirectionPosition, SettingsElement },
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Position"
    }
  }
}
</script>
