<template>
  <div class="min-h-screen w-full bg-gray-100 pt-20 text-gray-600">
    <div class="w-128 mx-auto bg-white rounded-md shadow-lg bg-white px-8 py-8">
      <h1 class="text-3xl font-medium tracking-wide">
        <slot name="title"></slot>
      </h1>
      <div class="mt-8">
        <slot name="form"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>
