<template>
  <dropdown-select v-model="value" @change="set">
    <option value="auto">Auto</option>
    <option value="visible">Visible</option>
    <option value="hidden">Hidden</option>
    <option value="scroll">Scroll</option>
  </dropdown-select>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "Overflow",
  components: { DropdownSelect },
  data() {
    return {
      value: ""
    }
  },
  mixins: [appearanceChangerMixin],
  methods: {
    set() {
      this.setStyleVariableValue({
        env: this.env,
        variable: this.variable,
        value: this.value
      })
    }
  },
  created() {
    this.value = this.getStyleVariableValue(this.env, this.variable, 0)
  }
}
</script>
