<template>
  <div class="flex items-center">
    <dropdown-select v-model="current" class="mr-6" @change="set">
      <option value="block">Visible</option>
      <option value="none">Hidden</option>
    </dropdown-select>
    <label v-if="showAfter && current === 'block' && canBeDelayed" class="flex items-center">
      <span class="mr-2">After:</span>
      <input-with-unit v-model="showAfter" :alternative-units="['sec']" size="normal" @updated="setDelay" />
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "Visibility",
  components: { DropdownSelect, InputWithUnit },
  mixins: [appearanceChangerMixin],
  props: {
    canBeDelayed: {
      default: false,
      type: Boolean
    },
    selector: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showAfter: null,
      current: ""
    }
  },
  computed: {
    ...mapGetters("preview", ["getPageSelector", "getIframeDocuments"]),
    visibilityDelayAttrKey() {
      return `data-${this.env}-visibility-delay`
    }
  },
  methods: {
    set(event) {
      this.setStyleVariableValue({
        env: this.env,
        variable: this.variable,
        value: event.target.value
      })
    },
    setDelay() {
      this.getIframeDocuments.forEach((doc) =>
        doc.querySelector(`${this.getPageSelector} ${this.selector}`).setAttribute(this.visibilityDelayAttrKey, this.showAfter.value)
      )
    }
  },
  created() {
    if (this.canBeDelayed) {
      this.showAfter = {
        unit: "sec",
        value: this.getIframeDocuments[0]
          .querySelector(`${this.getPageSelector} ${this.selector}`)
          .getAttribute(this.visibilityDelayAttrKey)
      }
    }
    this.current = this.getStyleVariableValue(this.env, this.variable, 0)
  }
}
</script>
