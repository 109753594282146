<template>
  <div class="payment-methods text-gray-800">
    <div class="px-8 py-6 shadow-lg bg-white rounded-xl">
      <heading class="mb-6">Payment Methods</heading>
      <div v-show="paymentMethodsLoadStatus === 'loading'">Loading...</div>
      <dashboard-error v-show="paymentMethodsLoadStatus === 'success' && paymentMethods.length === 0">
        There is no available method. Add at least one to subscribe.
      </dashboard-error>
      <div v-show="paymentMethodsLoadStatus === 'success' && paymentMethods.length > 0">
        <div
          v-for="(method, key) in paymentMethods"
          :key="'method-' + key"
          class="px-4 py-3 rounded-lg bg-indigo-100 border-2 border-indigo-200 my-2 flex items-center justify-between"
        >
          <div class="font-medium">{{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}</div>
          <p>Ending In: {{ method.last_four }}</p>
          <p>Expired: {{ method.exp_month }} / {{ method.exp_year }}</p>
          <button class="bg-red-500 text-white px-2 py-1 rounded-md" @click.stop="removePaymentMethod(method.id)">Remove</button>
        </div>
      </div>
    </div>
    <div class="mt-8 px-8 py-6 shadow-lg bg-white rounded-xl">
      <heading class="mb-6">Add New Payment Method</heading>
      <dashboard-error v-if="addPaymentStatus === 'error'">{{ addPaymentStatusError }}</dashboard-error>
      <div v-if="addPaymentStatus === 'loading'">Payment method is adding...</div>
      <div v-if="addPaymentStatus === 'success'">Payment method is added</div>
      <div>
        <div class="flex items-center mb-6">
          <label class="w-48 whitespace-nowrap text-medium">Card Holder Name</label>
          <text-input v-model="name" type="text" />
        </div>
        <div class="flex items-center mb-6">
          <label class="w-48 whitespace-nowrap text-medium">Card</label>
          <div id="card-element"></div>
        </div>
      </div>
      <div class="text-right">
        <button class="px-4 py-2 bg-indigo-600 text-white rounded-md" @click="submitPaymentMethod()">Save Payment Method</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import TextInput from "@/components/common/TextInput"
import Heading from "@/components/dashboard/Title"
import DashboardError from "@/components/dashboard/DashboardError"

export default {
  name: "PaymentMethods",
  components: { DashboardError, Heading, TextInput },
  mounted() {
    this.addStripeToPage()
    this.loadPaymentMethods()
  },
  data() {
    return {
      stripeAPIToken: "",
      stripe: "",
      card: "",
      paymentMethodSelected: {},
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      name: "",
      addPaymentStatus: "",
      addPaymentStatusError: "",
      paymentMethods: [],
      paymentMethodsLoadStatus: ""
    }
  },
  methods: {
    ...mapActions("user", [
      "fetchStripeKey",
      "addPaymentMethod",
      "fetchPaymentMethods",
      "fetchStripeSetupIntentToken",
      "deletePaymentMethod"
    ]),
    async submitPaymentMethod() {
      const intentToken = await this.fetchStripeSetupIntentToken()
      this.addPaymentStatus = "loading"
      const result = await this.stripe.confirmCardSetup(intentToken, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.name
            // address: this.address,
          }
        }
      })
      if (result.error) {
        this.addPaymentStatus = "error"
        this.addPaymentStatusError = result.error.message
      } else {
        await this.addPaymentMethod(result.setupIntent.payment_method)
        this.addPaymentStatus = "success"
        this.card.clear()
        this.name = ""
        await this.loadPaymentMethods()
      }
    },

    async addStripeToPage() {
      this.stripeAPIToken = await this.fetchStripeKey()
      const documentTag = document
      const tag = "script"
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = "//js.stripe.com/v3/"
      object.addEventListener(
        "load",
        function () {
          // eslint-disable-next-line no-undef
          this.stripe = Stripe(this.stripeAPIToken)
          const elements = this.stripe.elements()
          this.card = elements.create("card", { hidePostalCode: true, style: this.cardStyle })
          this.card.mount("#card-element")
        }.bind(this),
        false
      )
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    async loadPaymentMethods() {
      this.paymentMethodsLoadStatus = "loading"
      this.paymentMethods = await this.fetchPaymentMethods()
      this.paymentMethodsLoadStatus = "success"
    },

    async removePaymentMethod(paymentID) {
      this.paymentMethodsLoadStatus = "loading"
      await this.deletePaymentMethod(paymentID)
      await this.loadPaymentMethods()
    }
  }
}
</script>
<style lang="scss">
.payment-methods {
  .add-payment-method {
    .form-field {
      display: grid;
      grid-template-columns: 160px 1fr;
      align-items: center;
      justify-content: stretch;
      margin: 20px 0;

      label {
        min-width: 170px;
        white-space: nowrap;
        margin-right: 20px;
      }

      input {
        height: 40px;
        padding: 0 16px;
      }
    }
  }

  .StripeElement {
    @apply h-12 w-full border-2 border-gray-200 px-3 pt-3 rounded-md bg-white shadow-md transition-all;
  }

  .StripeElement--focus {
    @apply shadow-lg;
  }

  .StripeElement--invalid {
    @apply border-red-500 border-2;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  #add-card-button {
    margin: 0 auto;
    display: block;
    padding: 6px 10px;
    background: steelblue;
    color: #fff;
    box-shadow: 0 2px 3px 0 #aaa;
    border-radius: 4px;
  }
}
</style>
