<template>
  <settings-element>
    <template #title>Blur</template>
    <template #body>
      <BackdropBlur :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import BackdropBlur from "@/components/campaign-builder/appearance-changers/BackdropBlur"

export default {
  name: "SettingsBlur",
  components: { BackdropBlur, SettingsElement },
  props: {
    variable: {
      type: String,
      required: true
    }
  }
}
</script>
