<template>
  <div class="home">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      title: "Marketing App!"
    }
  },
  mounted() {
    const doc = new DOMParser().parseFromString("HTML WILL BE HERE", "text/html")
    let pageNumbers = []
    doc.querySelectorAll(".page").forEach((page) => pageNumbers.push(page.classList[1].replace("page", "")))
    let styleVariables = {}
    const uVariables = doc.querySelector("style#osm-user").innerHTML

    pageNumbers.forEach((pageNumber) => {
      styleVariables[pageNumber] = {
        style: {
          desktop: {},
          mobile: {}
        }
      }
      const regex = new RegExp(".page" + pageNumber + "{(.*?)}", "g")
      const match = uVariables.match(regex)
      const cssRowsDesktop = match[0]
        .replace(`.page${pageNumber}{`, "")
        .replace("}", "")
        .split(";")
        .filter((value) => {
          return value !== ""
        })

      cssRowsDesktop.forEach((cssRow) => {
        const key = cssRow.split(":")[0]
        const value = cssRow.split(":")[1]
        styleVariables[pageNumber]["style"]["desktop"][key] = value
      })

      const cssRowsMobile = match[1]
        .replace(`.page${pageNumber}{`, "")
        .replace("}", "")
        .split(";")
        .filter((value) => {
          return value !== ""
        })

      cssRowsMobile.forEach((cssRow) => {
        const key = cssRow.split(":")[0]
        const value = cssRow.split(":")[1]
        styleVariables[pageNumber]["style"]["mobile"][key] = value
      })
    })
  }
}
</script>
