<template>
  <div class="w-full my-4">
    <label class="flex text-gray-700 items-center justify-content-end cursor-pointer">
      <input
        :checked="value"
        :disabled="disabled"
        class="mr-2 w-4 h-4 cursor-pointer"
        type="checkbox"
        @change="$emit('change', $event)"
        @input="input"
      />
      <span class="text-base"><slot /></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      required: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.checked)
    }
  }
}
</script>
