<template>
  <settings-element>
    <template #title>Margin</template>
    <template #body>
      <Margin :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Margin from "@/components/campaign-builder/appearance-changers/Margin"

export default {
  name: "SettingsPadding",
  props: {
    variable: {
      type: String,
      required: true
    }
  },
  components: { Margin, SettingsElement }
}
</script>
