<template>
  <div class="border-radius relative h-28 w-80 rounded-lg flex items-center justify-center p-2 border border-gray-200">
    <span class="font-medium">Radius</span>
    <InputWithUnit v-model="top" :alternative-units="['px', '%']" class="border-radius-field" size="full" @updated="setData" />
    <InputWithUnit v-model="right" :alternative-units="['px', '%']" class="border-radius-field" size="full" @updated="setData" />
    <InputWithUnit v-model="bottom" :alternative-units="['px', '%']" class="border-radius-field" size="full" @updated="setData" />
    <InputWithUnit v-model="left" :alternative-units="['px', '%']" class="border-radius-field" size="full" @updated="setData" />
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "BorderRadius",
  components: { InputWithUnit },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      top: {},
      right: {},
      bottom: {},
      left: {}
    }
  },
  methods: {
    setData() {
      if (isNaN(this.top.value) || this.top.value === "") {
        this.top.value = 0
      }
      if (isNaN(this.right.value) || this.right.value === "") {
        this.right.value = 0
      }
      if (isNaN(this.bottom.value) || this.bottom.value === "") {
        this.bottom.value = 0
      }
      if (isNaN(this.left.value) || this.left.value === "") {
        this.left.value = 0
      }
      const str = `${this.top.value}${this.top.unit} ${this.right.value}${this.right.unit} ${this.bottom.value}${this.bottom.unit} ${this.left.value}${this.left.unit}`
      this.setStyleVariableDirect({
        env: this.env,
        value: str,
        variable: this.variable
      })
    }
  },
  created() {
    const data = this.getStyleVariableValue(this.env, this.variable, 2)
    this.top = {
      value: data[0].value,
      unit: data[0].unit
    }
    this.right = {
      value: data[1].value,
      unit: data[1].unit
    }
    this.bottom = {
      value: data[2].value,
      unit: data[2].unit
    }
    this.left = {
      value: data[3].value,
      unit: data[3].unit
    }
  }
}
</script>
<style lang="scss" scoped>
.border-radius {
  &-field {
    @apply absolute;
    max-width: 6.5rem;

    &:nth-of-type(1) {
      @apply top-2 left-2;
    }

    &:nth-of-type(2) {
      @apply top-2 right-2;
    }

    &:nth-of-type(3) {
      @apply bottom-2 right-2;
    }

    &:nth-of-type(4) {
      @apply bottom-2 left-2;
    }
  }
}
</style>
