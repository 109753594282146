import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import CreateCampaign from "@/views/CreateCampaign"
import Campaigns from "@/views/Campaigns"
import authRoutes from "@/router/auth"
import billingRoutes from "@/router/billing"
import Dashboard from "@/views/Dashboard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: Campaigns
  },
  {
    path: "/campaigns/edit",
    name: "EditCampaign",
    component: () => import(/* webpackChunkName: "editCampaign" */ "../views/EditCampaign")
  },
  {
    path: "/campaigns/create",
    name: "CreateCampaign",
    component: CreateCampaign
  },
  ...authRoutes,
  ...billingRoutes
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
