<template>
  <auth-layout>
    <template #title>Register</template>
    <template #form>
      <dashboard-error v-if="error">{{ error }}</dashboard-error>
      <form @submit.prevent="submit">
        <auth-form-field>
          <template #label>Name</template>
          <template #input>
            <text-input v-model="name" type="text" />
          </template>
        </auth-form-field>
        <auth-form-field>
          <template #label>Email</template>
          <template #input>
            <text-input v-model="email" type="text" />
          </template>
        </auth-form-field>
        <auth-form-field>
          <template #label>Password</template>
          <template #input>
            <text-input v-model="password" type="text" />
          </template>
        </auth-form-field>
        <auth-form-field>
          <template #label>Password Confirmation</template>
          <template #input>
            <text-input v-model="password_confirmation" type="text" />
          </template>
        </auth-form-field>
        <div class="flex items-center justify-between">
          <router-link :to="{ name: 'Login' }" class="text-medium text-indigo-700">Login</router-link>
          <button class="px-6 py-2 bg-indigo-600 text-white text-medium rounded-lg shadow-lg" type="submit">Register</button>
        </div>
      </form>
    </template>
  </auth-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import AuthLayout from "@/layout/AuthLayout"
import AuthFormField from "@/components/auth/AuthFormField"
import TextInput from "@/components/common/TextInput"
import DashboardError from "@/components/dashboard/DashboardError"

export default {
  name: "Register",
  components: { DashboardError, TextInput, AuthFormField, AuthLayout },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: ""
    }
  },
  computed: mapState("user", ["error"]),
  methods: {
    ...mapActions({ register: "user/register" }),
    ...mapMutations({ setError: "user/setError" }),
    submit() {
      this.setError(null)
      this.register(this.$data).then(() => {
        if (this.error === null) this.$router.push({ name: "Dashboard" })
      })
    }
  },
  created() {
    this.setError(null)
  }
}
</script>
