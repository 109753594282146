<template>
  <dashboard-layout>
    <div>
      <div class="mb-6 px-2 py-4 rounded-lg bg-indigo-600">
        <router-link
          :to="{ name: 'BillingPlans' }"
          class="mx-2 px-4 py-2 rounded-md text-white font-medium"
          exact-active-class="bg-gray-900 bg-opacity-25"
          >Plans
        </router-link>
        <router-link
          :to="{ name: 'BillingPaymentMethods' }"
          class="mx-2 px-4 py-2 rounded-md text-white font-medium"
          exact-active-class="bg-gray-900 bg-opacity-25"
          >Payment Methods
        </router-link>
        <router-link
          :to="{ name: 'BillingAddress' }"
          class="mx-2 px-4 py-2 rounded-md text-white font-medium"
          exact-active-class="bg-gray-900 bg-opacity-25"
          >Address
        </router-link>
        <router-link
          :to="{ name: 'BillingInvoices' }"
          class="mx-2 px-4 py-2 rounded-md text-white font-medium"
          exact-active-class="bg-gray-900 bg-opacity-25"
          >Invoices
        </router-link>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layout/DashboardLayout"

export default {
  name: "Billing",
  components: { DashboardLayout },
  data() {
    return {}
  },

  mounted() {}
}
</script>

<style lang="scss" scoped></style>
