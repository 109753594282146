import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "@/assets/tailwind.css"
import "@/assets/variables.css"
import "@/assets/scss/main.scss"
import routerBeforeEach from "@/bootstrap/routerBeforeEach"

require("./bootstrap/axios")
require("./bootstrap/vueToastNotification")

router.beforeEach(routerBeforeEach)
// Vue.config.productionTip = false
require("./bootstrap/globalComponents")
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
