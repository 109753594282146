<template>
  <SettingsColor :variable="variable" title="Text Color" />
</template>
<script>
export default {
  name: "SettingsTextColor",
  props: {
    variable: {
      type: String,
      required: true
    }
  }
}
</script>
