import store from "@/store/index"

const routerBeforeEach = (to, from, next) => {
  if (!window.localStorage.getItem("access_token")) {
    if (to.path !== "/register" && to.path !== "/login") {
      // @todo Activate login for production.
      // window.localStorage.setItem("redirect-after-auth", to.fullPath);
      // next({ name: "Login" });
      next()
    } else {
      next()
    }
  } else {
    window.axios.defaults.headers.common["Authorization"] = "Bearer " + window.localStorage.getItem("access_token")
    if (to.path === "/register" || to.path === "/login") {
      // next({ name: "Dashboard" })
      // @todo Activate login for production.
      next()
    } else {
      if (store.state.user.user === null) {
        store
          .dispatch("user/fetchCurrentUser")
          .then(() => next())
          .catch(() => {
            store.commit("user/clear")
            next({ name: "Login" })
          })
      } else {
        next()
      }
    }
  }
}
export default routerBeforeEach
