import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"

const closeButton = `<button class="close-button" data-close="true" data-mobile-visibility-delay="0" data-desktop-visibility-delay="0">
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
<line x1="368" y1="368" x2="144" y2="144"
style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/>
<line x1="368" y1="144" x2="144" y2="368"
style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/>
</svg>
</button>`
const expandButton = `<button class="expand-button">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
</button>`

class modalHtml {
  constructor(positionClass, layoutClass, bodyClass, components, img) {
    this.randId = randomId()
    this.components = components
    this.positionClass = positionClass
    this.layoutClass = layoutClass
    this.bodyClass = bodyClass
    this.img = img
  }

  get() {
    let positionClass = this.positionClass
    let titleButton = "close"
    if (positionClass === "panel-right" || positionClass === "panel-left") {
      positionClass += " panel collapsed"
      titleButton = "expand-toggle"
    }
    const fontUrl = getImportUrl(["Poppins"])
    let html = `
            <link href="${fontUrl}" rel="stylesheet">
              <div id="${this.randId}" data-parent>
              <div class="position ${positionClass}" data-position>
              <div class="inner ${this.layoutClass}" data-layout="${this.layoutClass}">
              <div class="layout">
              ${titleButton === "close" ? closeButton : ""}
              ${titleButton === "expand-toggle" ? expandButton : ""}
              <div class="images"> `
    html += typeof this.img !== "undefined" ? `<img id="${randomId()}" src="${this.img}"/>` : ""
    html += `</div> `
    if (this.positionClass === "panel-right" || this.positionClass === "panel-left") {
      html += `${this.generateTitle()}<div class="body" class="${this.bodyClass}">`
    } else {
      html += `<div class="body" class="${this.bodyClass}">${this.generateTitle()}`
    }
    html += `<div class="subtitle"></div>
              <div class="description">${this.components.description ? this.components.description : ""}</div>
              <div class="form-and-buttons">
              <form class="form">${this.generateForm()}</form>
              <div class="buttons">${this.generateButtons()}</div>
              </div>
              <div class="notes">${this.components.notes ? this.components.notes : ""}</div>
            </div>
        </div>
        </div>
        </div>
        <div class="overlay" data-close="true" data-overlay></div>  
        </div>`
    return html
  }

  generateTitle() {
    return this.components.title && this.components.title !== ""
      ? `<div class="title">${this.components.title}</div>`
      : `<div class="title"></div>`
  }

  generateForm() {
    let html = ``
    if (this.components.form && this.components.form.length > 0) {
      this.components.form.forEach((item) => {
        if (item.type === "email") html += this.generateEmailField(item)
      })
    }
    return html
  }

  generateButtons() {
    let html = ``
    if (this.components.buttons && this.components.buttons.length > 0) {
      this.components.buttons.forEach((item) => {
        html += `<button class="${item.type}" ${item.type === "primary" ? "data-submit='true' data-count-as='conversion'" : ""}>${
          item.text
        }</button>`
      })
    }
    return html
  }

  generateEmailField(input) {
    const randName = "email-" + randomId()
    let html = `<div class="field">`
    if (input.label !== "") html += `<label for="${randName}">${input.label}</label>`
    html += `<input required='true' type="email" autocomplete="off" id="${randName}" class="user-input" name="${input.name}" placeholder="${input.placeholder}" value="${input.text}"/>`
    html += "</div>"
    return html
  }
}

export { modalHtml }
