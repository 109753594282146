<template>
  <settings-element>
    <template #title>Padding</template>
    <template #body>
      <Padding :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Padding from "@/components/campaign-builder/appearance-changers/Padding"

export default {
  name: "SettingsPadding",
  props: {
    variable: {
      type: String,
      required: true
    }
  },
  components: { Padding, SettingsElement }
}
</script>
