<template>
  <div class="bg-white rounded-xl shadow-lg bg-white px-8 py-6">
    <heading>Plans</heading>
    <dashboard-error v-if="error"> {{ error }}</dashboard-error>
    <div>
      <div v-if="loading">Loading..</div>
      <div v-else class="flex items-center mt-4 justify-between">
        <div
          v-for="plan in plans"
          :key="plan['stripe_plan']"
          :class="{ selected: selectedPlan === plan['stripe_plan'], current: userCurrentPlan === plan['stripe_plan'] }"
          class="px-6 py-4 text-center w-1/5 rounded-lg shadow-lg plan"
        >
          <div class="text-2xl font-medium mb-6">{{ plan.name }}</div>
          <p class="mb-8">$ {{ plan["price"] }}/mo.</p>
          <button
            :disabled="userCurrentPlan === plan['stripe_plan']"
            class="bg-white text-gray-700 px-2 py-1 shadow-md rounded-md disabled:opacity-50"
            @click="selectedPlan = plan['stripe_plan']"
          >
            Select Plan
          </button>
        </div>
      </div>
      <div class="text-right mt-4">
        <button class="bg-indigo-600 text-white mt-4 px-4 py-2 rounded-lg shadow-md font-medium" @click="updateSubscription">
          Subscribe
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Heading from "@/components/dashboard/Title"
import DashboardError from "@/components/dashboard/DashboardError"

export default {
  name: "Plans",
  components: { DashboardError, Heading },
  data() {
    return {
      status: null,
      loading: true,
      selectedPlan: "",
      plans: []
    }
  },
  computed: {
    ...mapState("user", ["error"]),
    userCurrentPlan() {
      const user = this.$store.state.user.user
      if (user) {
        return user.plan["stripe_plan"]
      }
      return null
    }
  },
  methods: {
    ...mapActions("user", ["fetchPlans", "updatePlan"]),
    async updateSubscription() {
      this.loading = true
      await this.updatePlan(this.selectedPlan)
      window.location.reload()
    }
  },
  created() {
    this.loading = true
    this.fetchPlans()
      .then((plans) => {
        this.plans = plans.plans
        this.loading = false
        Object.keys(this.plans).forEach((p) => {
          console.log(p)
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
</script>

<style lang="scss" scoped>
.plan {
  @apply bg-indigo-200 text-gray-700;
  &.current {
    @apply bg-gray-200;
  }

  &.selected {
    @apply bg-indigo-700 text-white;
  }
}
</style>
