<template>
  <settings-element>
    <template #title>Font Size</template>
    <template #body>
      <FontSize :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import FontSize from "@/components/campaign-builder/appearance-changers/FontSize"

export default {
  name: "SettingsFontSize",
  components: { FontSize, SettingsElement },
  props: {
    variable: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
