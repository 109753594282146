import PaymentMethods from "@/views/Billing/PaymentMethods"
import Plans from "@/views/Billing/Plans"
import Address from "@/views/Billing/Address"
import Invoices from "@/views/Billing/Invoices"
import Billing from "@/views/Billing/Billing"

const billingRoutes = [
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    redirect: { name: "BillingPlans" },
    children: [
      {
        path: "payment-methods",
        name: "BillingPaymentMethods",
        component: PaymentMethods
      },
      {
        path: "plans",
        name: "BillingPlans",
        component: Plans
      },
      {
        path: "address",
        name: "BillingAddress",
        component: Address
      },
      {
        path: "invoices",
        name: "BillingInvoices",
        component: Invoices
      }
    ]
  }
]

export default billingRoutes
