<template>
  <settings-element>
    <template #title>Visibility</template>
    <template #body>
      <Hidden :selector="selector" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Hidden from "@/components/campaign-builder/appearance-changers/Hidden"

export default {
  name: "SettingsHidden",
  props: {
    selector: {
      type: String,
      required: true
    }
  },
  components: { Hidden, SettingsElement }
}
</script>
