<template>
  <div>
    <settings-element>
      <template #title>Looking</template>
      <template #body>
        <dropdown-select v-if="appearanceDevice === 'desktop'" v-model="desktopLookingClass" @change="setLooking">
          <option :selected="desktopLookingClass === ''" value="">Default</option>
          <option :selected="desktopLookingClass === 'd-plain'" value="d-plain">Plain</option>
          <option :selected="desktopLookingClass === 'd-paper'" value="d-paper">Paper</option>
        </dropdown-select>
        <dropdown-select v-if="appearanceDevice === 'mobile'" v-model="mobileLookingClass" @change="setLooking">
          <option :selected="mobileLookingClass === ''" value="">Default</option>
          <option :selected="mobileLookingClass === 'm-plain'" value="m-plain">Plain</option>
          <option :selected="mobileLookingClass === 'm-paper'" value="m-paper">Paper</option>
        </dropdown-select>
      </template>
    </settings-element>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "SettingFormLooking",
  components: { DropdownSelect, SettingsElement },
  data() {
    return {
      desktopLookingClass: "",
      mobileLookingClass: ""
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
    ...mapState("preview", ["appearanceDevice"])
  },
  methods: {
    setLooking() {
      this.getIframeDocuments.forEach((doc) => {
        const f = doc.querySelector(`${this.getPageSelector} form.form`)
        f.classList.remove("d-plain", "m-plain", "d-paper", "m-paper")
        const classes = [this.mobileLookingClass, this.desktopLookingClass]
        classes.forEach((cl) => (cl.length > 0 ? f.classList.add(cl) : ""))
      })
    }
  },
  mounted() {
    let f = this.getIframeDocuments[0].querySelector(`${this.getPageSelector} form.form`)
    if (f.classList.contains("d-plain")) {
      this.desktopLookingClass = "d-plain"
    } else if (f.classList.contains("d-paper")) {
      this.desktopLookingClass = "d-paper"
    } else {
      this.desktopLookingClass = ""
    }
    if (f.classList.contains("m-plain")) {
      this.mobileLookingClass = "m-plain"
    } else if (f.classList.contains("m-paper")) {
      this.mobileLookingClass = "m-paper"
    } else {
      this.mobileLookingClass = ""
    }
  }
}
</script>
