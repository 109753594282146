import userAPI from "@/api/user"
import generateAPIErrorMessage from "@/api/generate-api-error-message"

const user = {
  state: {
    accessToken: localStorage.getItem("access_token") || "",
    sites: [],
    user: null,
    address: null,
    taxId: null,
    error: null,
    success: null
  },
  getters: {
    isLoggedIn: (state) => state.accessToken !== null && state.user,
    getCampaigns(state) {
      const campaigns = []
      state.sites.forEach((site) => {
        site.campaigns.forEach((campaign) => {
          campaign.domain = site.domain
          campaigns.push(campaign)
        })
      })
      return campaigns
    }
  },
  mutations: {
    userLogin(state, payload) {
      localStorage.setItem("access_token", payload["access_token"])
      state.accessToken = payload["access_token"]
      state.user = payload.user
    },
    setUser(state, payload) {
      state.user = payload
    },
    setSites(state, payload) {
      state.sites = payload
    },
    setAddressAndTaxId(state, payload) {
      if (payload.address === null) {
        state.address = { line1: "", line2: "", country: "", city: "", postal_code: "", state: "" }
      } else {
        state.address = payload.address
      }
      state.taxId = payload["tax_id"]
    },
    userLogout(state) {
      window.localStorage.removeItem("access_token")
      state.accessToken = null
      state.user = {}
    },
    setUserSites(state, payload) {
      state.sites = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setSuccess(state, payload) {
      state.success = payload
    },
    clear(state) {
      state.accessToken = null
      state.user = null
      window.localStorage.removeItem("access_token")
    }
  },
  actions: {
    async register({ commit }, { name, email, password, password_confirmation }) {
      return userAPI
        .register({ name, email, password, password_confirmation })
        .then((resp) => commit("userLogin", resp.data.data))
        .catch((err) => commit("setError", generateAPIErrorMessage(err)))
    },
    async login({ commit }, { email, password }) {
      return userAPI
        .login({ email, password })
        .then((resp) => commit("userLogin", resp.data.data))
        .catch((err) => commit("setError", generateAPIErrorMessage(err)))
    },
    async fetchAddress({ commit }) {
      return userAPI
        .fetchAddressAndTaxId()
        .then((resp) => commit("setAddressAndTaxId", resp.data.data))
        .catch((err) => commit("setError", generateAPIErrorMessage(err)))
    },
    async fetchCurrentUser({ commit }) {
      return userAPI
        .fetchCurrentUser()
        .then((resp) => commit("setUser", resp.data.data.user))
        .catch(() => commit("clear"))
    },
    async updatePlan({ commit }, planId) {
      return userAPI
        .updateUserPlan({ planId })
        .then((resp) => resp)
        .catch((err) => commit("setError", generateAPIErrorMessage(err)))
    },
    async fetchPlans() {
      return userAPI
        .fetchAvailablePlans()
        .then((resp) => resp.data.data)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchSites({ commit }) {
      return userAPI
        .fetchSites()
        .then((resp) => commit("setSites", resp.data.data.sites))
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchInvoices() {
      return userAPI
        .fetchInvoices()
        .then((resp) => resp.data.data.invoices)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchStripeKey() {
      return userAPI
        .fetchStripePublicApiKey()
        .then((resp) => {
          return resp.data.data["stripe_public_key"]
        })
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchStripeSetupIntentToken() {
      return userAPI
        .createAndFetchStripeSetupIntent()
        .then((resp) => resp.data.data["intent_data"]["client_secret"])
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async addPaymentMethod(_, paymentMethod) {
      return userAPI
        .addPaymentMethod(paymentMethod)
        .then(() => true)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchPaymentMethods() {
      return userAPI
        .fetchPaymentMethods()
        .then((res) => res.data.data["payment_methods"])
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async deletePaymentMethod(_, paymentMethod) {
      return userAPI
        .deletePaymentMethod(paymentMethod)
        .then(() => true)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async updateAddress({ commit }, { taxId, address }) {
      return userAPI
        .saveAddress({ taxId, address })
        .then(() => commit("setSuccess", "Address is updated!"))
        .catch((err) => commit("setError", generateAPIErrorMessage(err)))
    },
    async logout({ commit }) {
      await window.axios.post("/logout")
      commit("userLogout")
    }
    // async fetchSites({ commit }) {
    //   const result = await window.axios.get("sites")
    //   commit("setUserSites", result.data)
    // },
    // async getUser({ commit }) {
    //   try {
    //     const user = await window.axios.get("user")
    //     commit("setUser", user.data)
    //   } catch (e) {
    //     commit("clear")
    //   }
    // }
  },
  namespaced: true
}

export default user
