<template>
  <input
    :class="classes"
    :required="required"
    :type="type"
    :value="value"
    class="leading-normal w-full text-gray-800 bg-white font-sans rounded-lg text-left appearance-none outline-none"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event.target.value)"
    @keydown="$emit('keydown', $event)"
    @keyup="$emit('keyup', $event)"
  />
</template>

<script>
export default {
  name: "TextInput",
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text"
    },
    required: {
      type: Boolean,
      default: false
    },
    padding: { type: String, default: "normal" }, // normal, small
    value: {},
    bordered: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes() {
      return {
        "border-2 focus:border-gray-400": this.bordered === true,
        "border bg-gray-200 focus:bg-white": this.bordered === false,
        "px-2 py-2": this.padding === "normal",
        "px-2 py-1": this.padding === "small"
      }
    }
  }
}
</script>
