<template>
  <div class="h-full bg-gray-50 flex">
    <SidebarNav />
    <div class="flex-1">
      <HeaderNav />
      <div class="py-4 px-8">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from "@/components/dashboard/HeaderNav"
import SidebarNav from "@/components/dashboard/SidebarNav"

export default {
  name: "DashboardLayout",
  components: { SidebarNav, HeaderNav }
}
</script>

<style scoped></style>
