<template>
  <checkbox v-model="isHidden" @change="toggleHidden"> Hide on {{ env }}</checkbox>
</template>

<script>
import { mapGetters } from "vuex"
import Checkbox from "@/components/common/Checkbox"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "Hidden",
  components: { Checkbox },
  props: {
    selector: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      className: null,
      isHidden: false
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"])
  },
  mixins: [appearanceChangerMixin],
  methods: {
    toggleHidden() {
      this.getIframeDocuments.forEach((doc) => {
        doc.querySelectorAll(this.getPageSelector + " " + this.selector).forEach((element) => {
          element.classList.toggle(this.className)
        })
      })
    }
  },
  mounted() {
    this.env === "desktop" ? (this.className = "desktop-hidden") : null
    this.env === "mobile" ? (this.className = "mobile-hidden") : null
    this.isHidden = this.getIframeDocuments[0].querySelector(this.getPageSelector + " " + this.selector).classList.contains(this.className)
  }
}
</script>
