<template>
  <div class="mb-12 mt-8">
    <h3 class="text-xl font-medium mb-4">
      <slot name="title" />
    </h3>
    <div>
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsElement"
}
</script>
