<template>
  <input-with-unit v-if="width" v-model="width" :alternative-units="['px', 'vw', '%']" size="small" @updated="set" />
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "Width",
  components: { InputWithUnit },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      width: {}
    }
  },
  methods: {
    set() {
      this.setStyleVariableDirect({
        env: this.env,
        variable: this.variable,
        value: this.width.value + this.width.unit
      })
    }
  },
  created() {
    const { value, unit } = this.getStyleVariableValue(this.env, this.variable, 1)
    this.width = { value, unit }
  }
}
</script>
