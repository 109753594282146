<template>
  <div class="campaign-type-selector">
    <div class="type-selector">
      <div class="selector-buttons">
        <campaign-type-select-button
          v-for="(template, kTemplate) in modalTemplateData"
          :key="kTemplate"
          :active="typeName === 'modal' && typeData['templateId'] === kTemplate"
          @click="setTypeName('modal') & setTypeData('templateId', kTemplate)"
        >
          {{ template.name }}
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'satis-meter'" @click="setTypeName('satis-meter')">
          SatisMeter
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'wheel-of-fortune'" @click="setTypeName('wheel-of-fortune')">
          Wheel Of Fortune
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'gift-picker'" @click="setTypeName('gift-picker')"
          >Gift Picker
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'flat-bar'" @click="setTypeName('flat-bar')"
          >Flat Bar
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'whatsapp'" @click="setTypeName('whatsapp')"
          >Whatsapp
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'image-button'" @click="setTypeName('image-button')"
          >Fixed Image Button
        </campaign-type-select-button>
        <campaign-type-select-button :active="typeName === 'just-image'" @click="setTypeName('just-image')"
          >Just Image
        </campaign-type-select-button>
      </div>
      <div class="right">
        <div v-if="typeName === 'modal'">
          <div v-for="(template, kTemplate) in modalTemplateData" :key="kTemplate">
            <div v-if="'templateId' in typeData && typeData['templateId'] === kTemplate" class="modal-sub-types">
              <div>
                <div class="font-medium text-2xl mb-2">Positions</div>
                <campaign-type-select-button
                  v-for="(position, kPosition) in template.positions"
                  :key="kPosition"
                  :active="typeData['positionId'] === kPosition"
                  @click="setTypeData('positionId', kPosition)"
                >
                  {{ position.name }}
                </campaign-type-select-button>
              </div>
              <div class="mt-8">
                <h3 class="font-medium text-2xl mb-2">Layouts</h3>
                <campaign-type-select-button
                  v-for="(layout, kLayout) in template.layouts"
                  :key="kLayout"
                  :active="typeData['layoutId'] === kLayout"
                  @click="setTypeData('layoutId', kLayout)"
                >
                  {{ layout.name }}
                </campaign-type-select-button>
              </div>
            </div>
          </div>
        </div>
        <button v-if="readyToCreate" class="px-6 py-2 font-medium bg-indigo-600 text-white rounded-lg" @click="next">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignTypeSelectButton from "@/components/campaign-type-select/CampaignTypeSelectButton"
import getModalTemplates from "@/lib/marketing-widgets/modalTemplates"

export default {
  name: "CampaignTypeSelector",
  components: { CampaignTypeSelectButton },
  data() {
    return {
      typeName: "",
      typeData: {}
    }
  },
  computed: {
    modalTemplateData() {
      return getModalTemplates()
    },
    readyToCreate() {
      if (
        this.typeName === "satis-meter" ||
        this.typeName === "just-image" ||
        this.typeName === "image-button" ||
        this.typeName === "wheel-of-fortune" ||
        this.typeName === "gift-picker" ||
        this.typeName === "flat-bar" ||
        this.typeName === "whatsapp"
      ) {
        return true
      }
      if (this.typeName === "modal" && "templateId" in this.typeData && "layoutId" in this.typeData && "positionId" in this.typeData) {
        return true
      }
      return false
    }
  },
  methods: {
    setTypeName(value) {
      this.typeName = value
      this.typeData = {}
    },
    setTypeData(key, value) {
      this.typeData[key] = value
      this.typeData = { ...this.typeData }
    },
    next() {
      this.$emit("next", { type: this.typeName, typeData: this.typeData })
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-type-selector {
  @apply max-w-6xl w-full p-6 mx-auto;
}

.type-selector {
  @apply flex;

  .selector-buttons {
    @apply w-52 mr-12 flex flex-col;

    button {
      @apply mr-4 mb-1;
    }
  }

  .right {
    @apply flex-1;
  }

  .modal-sub-types {
    @apply mb-6 border border-gray-200 p-6 rounded-lg;

    button {
      @apply mx-2 my-1;
    }
  }
}
</style>
