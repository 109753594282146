import { render, staticRenderFns } from "./SettingsFontFamily.vue?vue&type=template&id=532b9897&scoped=true&"
import script from "./SettingsFontFamily.vue?vue&type=script&lang=js&"
export * from "./SettingsFontFamily.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532b9897",
  null
  
)

export default component.exports