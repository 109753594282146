import { mapGetters, mapMutations, mapState } from "vuex"

const appearanceChangerMixin = {
  props: ["variable"],
  computed: {
    ...mapGetters("preview", ["getStyleVariableValue", "getStyleVariables"]),
    ...mapState("preview", { env: "appearanceDevice" })
  },
  methods: {
    ...mapMutations("preview", ["setStyleVariableDirect", "setStyleVariableValue"])
  }
}
export default appearanceChangerMixin
