<template>
  <settings-element>
    <template #title>Font Family</template>
    <template #body>
      <FontFamily :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import FontFamily from "@/components/campaign-builder/appearance-changers/FontFamily"

export default {
  name: "SettingsFontFamily",
  components: { FontFamily, SettingsElement },
  props: {
    variable: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
