<template>
  <settings-element>
    <template #title>{{ title }}</template>
    <template #body>
      <Overflow :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Overflow from "@/components/campaign-builder/appearance-changers/Overflow"

export default {
  name: "SettingsOverflow",
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Overflow"
    }
  },
  components: { Overflow, SettingsElement }
}
</script>
