<template>
  <component :is="tag" :class="sizeClass" class="text-gray-900 tracking-wide text leading-relaxed font-medium">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Heading",
  props: {
    size: {
      type: String,
      default: "2xl"
    },
    tag: {
      type: String,
      default: "h3"
    }
  },
  computed: {
    sizeClass() {
      return "text-" + this.size
    }
  }
}
</script>
