<template>
  <div class="px-8 py-6 bg-white rounded-xl shadow-lg">
    <heading>Invoice Address</heading>
    <div v-if="loading">Loading</div>
    <form v-else class="text-gray-700" @submit.prevent="saveAddress">
      <div class="my-4 flex items-center">
        <label class="w-44">Line 1 (required):</label>
        <text-input v-model="address.line1" :required="true" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">Line 2:</label>
        <text-input v-model="address.line2" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">Country:</label>
        <text-input v-model="address.country" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">City:</label>
        <text-input v-model="address.city" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">Postal Code:</label>
        <text-input v-model="address.postal_code" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">State:</label>
        <text-input v-model="address.state" type="text" />
      </div>
      <div class="my-4 flex items-center">
        <label class="w-44">Tax ID:</label>
        <text-input v-model="taxId" type="text" />
      </div>
      <div class="text-right">
        <button class="mt-8 bg-indigo-600 text-white font-medium px-4 py-2 rounded-lg shadow-md" type="submit">Save Address</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Heading from "@/components/dashboard/Title"
import TextInput from "@/components/common/TextInput"

export default {
  name: "Address",
  components: { TextInput, Heading },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState("user", ["address"]),
    taxId: {
      get() {
        return this.$store.state.user.taxId
      },
      set(value) {
        return (this.$store.state.user.taxId = value)
      }
    }
  },
  created() {
    this.loading = true
    this.fetchAddress().then(() => (this.loading = false))
  },
  methods: {
    ...mapActions("user", ["fetchAddress"]),
    async saveAddress() {
      await window.axios.post("user/customer-address", {
        address: this.address,
        tax_id: this.taxId
      })
    }
  }
}
</script>
