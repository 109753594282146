import parseUnit from "@/lib/utils/parse-unit"
import HtmlGenerator from "@/lib/marketing-widgets/html-generator"
import { getImportUrl } from "@/lib/utils/google-font"
import { parseLongHandCss } from "@/lib/utils/parse-long-hand-css" // eslint-disable-next-line
import getScreenshot from "@/api/get-screenshot"

const preview = {
  state: {
    parentId: "",
    initFromHtml: false,
    initFromZero: false,
    zeroConfig: {},
    htmlConfig: "",
    initSiteId: null,

    setAreaTopic: null,
    activeSettings: "content",
    isSetAreaActive: false,

    appearanceDevice: "mobile",

    html: "",
    styleVariables: { 1: { style: {} } },
    previewDeviceLoading: { desktop: true, mobile: true },
    currentPage: 1,
    totalPage: 1,
    previewDevices: {
      mobile: { title: "Mobile", width: 414, height: 736 },
      desktop: { title: "Desktop", width: 1024, height: 768 }
    },
    iframes: {},
    iframeBackgroundCache: {},
    outlinedSelectors: []
  },
  getters: {
    getParentId: (state) => state.parentId,
    getCurrentStyleVariables: (state) => state.styleVariables[state.currentPage],
    getPreviewDevices: (state) => state.previewDevices,
    getIframeBackgroundCache: (state) => state.iframeBackgroundCache,
    getIframes: (state) => state.iframes,
    getStyleVariables: (state) => state.styleVariables,
    getIframeDocuments: (state) => Object.values(state.iframes).map((iframe) => iframe.contentWindow.document),
    getCurrentPage: (state) => state.currentPage,
    getTotalPage: (state) => state.totalPage,
    getPageSelector: (state) => `.page${state.currentPage}`,
    getPreviewDevicesLoading: (state) => state.previewDeviceLoading.desktop && state.previewDeviceLoading.mobile,
    getStyleVariableValue: (state) => (env, variable, type) => {
      // type 0 for just value, type 1 for value with unit, type 2 for long hand data and units
      if (type === 0) return state.styleVariables[state.currentPage].style[env][variable]
      else if (type === 1) return parseUnit(state.styleVariables[state.currentPage].style[env][variable])
      else if (type === 2) return parseLongHandCss(state.styleVariables[state.currentPage].style[env][variable])
      else console.error("UNRECOGNIZED TYPE!")
    },
    getStyleVariablesText(state, getters, rootState, rootGetters) {
      const parentId = rootGetters["preview/getParentId"]
      let styleText = ""
      Object.keys(state.styleVariables).forEach((page) => {
        const pageStyleVariables = state.styleVariables[page].style
        const data = {
          mobile: { prefix: "@media (max-width: 959px) {", suffix: "}" },
          desktop: { prefix: "@media (min-width: 960px) {", suffix: "}" },
          all: { prefix: "", suffix: "" }
        }
        Object.keys(data).forEach((device) => {
          if (device in pageStyleVariables) {
            const { prefix, suffix } = data[device]
            styleText += `${prefix} #${parentId} .page${page}{`
            Object.keys(pageStyleVariables[device]).forEach((key) => (styleText += `${key}:${pageStyleVariables[device][key]};`))
            styleText += `} ${suffix}`
          }
        })
      })
      return styleText
    }
  },
  mutations: {
    setAppearanceDevice(state, payload) {
      state.appearanceDevice = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload
    },
    setPreviewDevicesLoading(state, { device, status }) {
      state.previewDeviceLoading[device] = status
      state.previewDeviceLoading = { ...state.previewDeviceLoading }
    },
    addPage(state) {
      state.styleVariables[Object.keys(state.styleVariables).length + 1] = {
        style: {}
      }
      state.styleVariables = { ...state.styleVariables }
      state.totalPage += 1
      state.currentPage = state.totalPage
    },
    removeStyleOfPage(state, pageNumber) {
      let newStyleVars = {}
      Object.keys(state.styleVariables).forEach((key) => {
        key = parseInt(key)
        if (key >= pageNumber && state.styleVariables[key + 1] !== undefined) {
          newStyleVars[key] = state.styleVariables[key + 1]
        } else if (key < pageNumber) {
          newStyleVars[key] = state.styleVariables[key]
        }
      })
      state.styleVariables = newStyleVars
    },
    addStyleVariables(state, payload) {
      state.styleVariables = { ...state.styleVariables, ...payload }
    },
    setTotalPage(state, payload) {
      state.totalPage = payload
    },
    initStyleVariables(state, payload) {
      state.styleVariables = payload
    },
    setHtml(state, payload) {
      state.html = payload
    },
    addIframe(state, payload) {
      state.iframes = { ...state.iframes, ...payload }
    },
    addOutline(state, { device, selector }) {
      Object.values(state.iframes).forEach((frame) => {
        state.outlinedSelectors.forEach((value) => {
          frame.contentWindow.document.querySelectorAll(value).forEach((element) => {
            element.style.removeProperty("outline")
            element.style.removeProperty("outline-offset")
          })
        })
      })
      state.iframes[device].contentWindow?.document.querySelectorAll(selector).forEach((element) => {
        state.outlinedSelectors.push(selector)
        element.style.outline = "2px dashed blue"
        element.style.outlineOffset = "-1px"
      })
    },
    clearOutlines(state) {
      Object.values(state.iframes).forEach((frame) => {
        state.outlinedSelectors.forEach((value) => {
          frame.contentWindow.document.querySelectorAll(value).forEach((element) => {
            element.style.removeProperty("outline")
            element.style.removeProperty("outline-offset")
          })
        })
      })
    },
    setStyleVariableValue(state, payload) {
      let { env, variable, value } = payload
      if ("key" in payload) {
        throw new DOMException("Set Style Variable With Key Is Deprecated!!")
      }

      const parsed = parseUnit(state.styleVariables[state.currentPage].style[env][variable])
      if (parsed) {
        value = value.toString() + parsed.unit
      }

      state.styleVariables[state.currentPage].style[env][variable] = value
    },

    setStyleVariableDirect(state, payload) {
      let { env, variable, value } = payload
      state.styleVariables[state.currentPage].style[env][variable] = value
    },

    setInitFromHtml(state, payload) {
      state.initFromHtml = payload
    },
    setInitSiteId(state, payload) {
      state.initSiteId = payload
    },
    setParentId(state, payload) {
      state.parentId = payload
    },
    setInitFromZero(state, payload) {
      state.initFromZero = payload
    },
    setZeroConfig(state, payload) {
      state.zeroConfig = payload
    },
    addIframeBackgroundCache(state, { key, url }) {
      state.iframeBackgroundCache[key] = url
    },
    setHtmlConfig(state, payload) {
      state.htmlConfig = payload
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async setBackgroundOfIframes({ getters, commit }, url) {
      if (!url.startsWith("https://")) url = "https://" + url
      await Promise.all(
        Object.keys(getters.getPreviewDevices).map((device) => {
          async function _(device, url) {
            const { width, height } = getters.getPreviewDevices[device]
            const iframe = getters.getIframes[device]
            let interval = setInterval(async function () {
              if (typeof iframe !== "undefined") {
                clearInterval(interval)
                const iframeBody = iframe.contentWindow.document.querySelector("body")
                iframeBody.style.backgroundImage = "unset"
                try {
                  let key = url + width + height
                  let screenShotURL = ""
                  if (key in getters.getIframeBackgroundCache) {
                    screenShotURL = getters.getIframeBackgroundCache[key]
                  } else {
                    screenShotURL = await getScreenshot({ url, width, height })
                    commit("addIframeBackgroundCache", { key, url: screenShotURL })
                  }
                  iframeBody.style.backgroundImage = `url('${screenShotURL}')`
                } catch (error) {
                  console.log("screenshot error" + error)
                }
              }
            }, 100)
          }

          _(device, url)
        })
      )
      return true
    },
    reloadIframes({ getters }) {
      Object.values(getters.getIframes).forEach((iframe) => {
        iframe.contentWindow.dispatchEvent(
          new CustomEvent("dashboardReload", {
            detail: {
              page: getters.getCurrentPage
            }
          })
        )
      })
    },
    changePage({ commit }, pageNumber) {
      commit("setCurrentPage", pageNumber)
      this.dispatch("preview/reloadIframes")
    },
    removePage({ commit, getters }) {
      commit("removeStyleOfPage", getters.getCurrentPage)
      commit("clearOutlines")
      let totalPage
      getters.getIframeDocuments.forEach((doc) => {
        doc.querySelector(`#${getters.getParentId} ${getters.getPageSelector}`).remove()
        const pages = doc.querySelectorAll(`#${getters.getParentId} .page`)
        totalPage = pages.length
        let pageNumber = 1
        pages.forEach((page) => {
          page.classList.remove(page.classList[1])
          page.classList.add(`page${pageNumber}`)
          page.setAttribute("data-page", pageNumber)
          page.setAttribute("data-total-page", pages.length.toString())
          pageNumber += 1
        })
      })
      commit("setTotalPage", totalPage)

      if (getters.getCurrentPage > getters.getTotalPage) {
        commit("setCurrentPage", getters.getTotalPage)
      }
      this.dispatch("preview/reloadIframes")
      this.dispatch("preview/setEditorFonts")
    },
    addNewPage({ commit, getters }, { type, typeData }) {
      commit("clearOutlines")
      const newPageNumber = getters.getTotalPage + 1
      const parentId = getters.getParentId
      const config = { type, typeData }
      let { styleVariables, html } = new HtmlGenerator(newPageNumber, config).getPage(parentId)
      commit("addPage")
      getters.getIframeDocuments.forEach((doc) => {
        doc.querySelector(`#${parentId} .page${newPageNumber - 1}`).insertAdjacentHTML("afterend", html)
        doc.querySelectorAll(`#${parentId} .page`).forEach((page) => {
          page.setAttribute("data-total-page", newPageNumber)
        })
      })
      commit("addStyleVariables", { [newPageNumber]: styleVariables })
      this.dispatch("preview/reloadIframes")
    },
    setEditorFonts({ getters }) {
      let fonts = []
      getters.getIframeDocuments[0].querySelectorAll("*").forEach((element) => {
        if (element.className.toString().includes("font-family")) {
          const classList = element.className.toString().split(/\s+/)
          classList.forEach((className) => {
            if (className.includes("font-family")) {
              fonts.push(className.replace("font-family-", "").replaceAll("-", " "))
            }
          })
        }
      })
      const url = getImportUrl([...new Set(fonts)])
      Object.values(getters.getIframeDocuments).forEach((doc) => {
        const link = doc.querySelector(`link.osm-editor-fonts`)
        link.setAttribute("href", url)
      })
    }
  },
  modules: {},
  namespaced: true
}
export default preview
