<template>
  <div class="bg-red-500 px-4 py-4 text-white rounded-lg my-8 whitespace-pre">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DashboardError"
}
</script>

<style scoped></style>
