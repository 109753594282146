import Vue from "vue"
import Vuex from "vuex"
import preview from "@/store/preview"
import user from "@/store/user"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    preview,
    user
  }
})
