<template>
  <settings-element>
    <template #title>{{ title }}</template>
    <template #body>
      <background-color :variable="variable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import BackgroundColor from "@/components/campaign-builder/appearance-changers/BackgroundColor"

export default {
  name: "SettingsBackgroundColor",
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Background Color"
    }
  },
  components: { BackgroundColor, SettingsElement }
}
</script>
