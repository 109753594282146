<template>
  <settings-element>
    <template #title>Border</template>
    <template #body>
      <Border :color-variable="colorVariable" :style-variable="styleVariable" :width-variable="widthVariable" />
    </template>
  </settings-element>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Border from "@/components/campaign-builder/appearance-changers/Border"

export default {
  name: "SettingsBorder",
  props: {
    colorVariable: {
      type: String,
      required: true
    },
    styleVariable: {
      type: String,
      required: true
    },
    widthVariable: {
      type: String,
      required: true
    }
  },
  components: { Border, SettingsElement }
}
</script>
