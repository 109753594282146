<template>
  <div>
    <div class="mb-4 flex items-center">
      <div class="w-28 pr-4">
        <dropdown-select v-model="horizontalAttribute" class="w-full" @change="setPositionAfterChange">
          <option value="right">Right</option>
          <option value="left">Left</option>
        </dropdown-select>
      </div>
      <InputWithUnit v-if="horizontal" v-model="horizontal" :alternative-units="['px']" size="small" @updated="setPositionAfterChange" />
    </div>
    <div class="flex items-center">
      <div class="w-28 pr-4">
        <dropdown-select v-model="verticalAttribute" class="w-full" @change="setPositionAfterChange">
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
        </dropdown-select>
      </div>
      <InputWithUnit v-if="vertical" v-model="vertical" :alternative-units="['px']" size="small" @updated="setPositionAfterChange" />
    </div>
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "TwoDirectionPosition",
  components: { DropdownSelect, InputWithUnit },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      horizontalAttribute: null,
      verticalAttribute: null,
      horizontal: null,
      vertical: null,

      topVariable: "",
      bottomVariable: "",
      leftVariable: "",
      rightVariable: ""
    }
  },
  methods: {
    setPositionAfterChange() {
      if (this.horizontalAttribute === "right") {
        this.set(this.leftVariable, "unset")
        this.set(this.rightVariable, this.horizontal.value + this.horizontal.unit)
      } else {
        this.set(this.rightVariable, "unset")
        this.set(this.leftVariable, this.horizontal.value + this.horizontal.unit)
      }

      if (this.verticalAttribute === "top") {
        this.set(this.bottomVariable, "unset")
        this.set(this.topVariable, this.vertical.value + this.vertical.unit)
      } else {
        this.set(this.topVariable, "unset")
        this.set(this.bottomVariable, this.vertical.value + this.vertical.unit)
      }
    },
    set(variable, value) {
      this.setStyleVariableDirect({
        env: this.env,
        variable: variable,
        value
      })
    }
  },
  created() {
    this.topVariable = this.variable.top
    this.bottomVariable = this.variable.bottom
    this.leftVariable = this.variable.left
    this.rightVariable = this.variable.right

    const topValue = this.getStyleVariableValue(this.env, this.topVariable, 1)
    const bottomValue = this.getStyleVariableValue(this.env, this.bottomVariable, 1)
    if (topValue.value === "unset") {
      this.verticalAttribute = "bottom"
      this.vertical = bottomValue
    } else {
      this.verticalAttribute = "top"
      this.vertical = topValue
    }

    const leftValue = this.getStyleVariableValue(this.env, this.leftVariable, 1)
    const rightValue = this.getStyleVariableValue(this.env, this.rightVariable, 1)
    if (leftValue.value === "unset") {
      this.horizontalAttribute = "right"
      this.horizontal = rightValue
    } else {
      this.horizontalAttribute = "left"
      this.horizontal = leftValue
    }
  }
}
</script>
