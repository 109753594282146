<template>
  <div class="color-gradient">
    <div class="colors">
      <div class="color">
        <span class="index">Color 1</span>
        <ColorPicker v-if="color1" :value="color1" @change="(c) => (this.color1 = c)" />
      </div>
      <div class="color">
        <span class="index">Color 2</span>
        <ColorPicker v-if="color2" :value="color2" @change="(c) => (this.color2 = c)" />
      </div>
    </div>
    <div class="angle">
      <span class="description">Direction</span>
      <div>
        <input v-model="degree" max="360" min="0" type="range" @input="setData" />
        <text-input v-model="degree" max="360" min="0" padding="small" type="number" @input="setData" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/campaign-builder/appearance-changers/base/ColorPicker"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import TextInput from "@/components/common/TextInput"

export default {
  name: "ColorGradient",
  mixins: [appearanceChangerMixin],
  components: { TextInput, ColorPicker },
  data() {
    return {
      color1: null,
      color2: null,
      degree: 0
    }
  },
  watch: {
    color1() {
      this.setData()
    },
    color2() {
      this.setData()
    }
  },
  methods: {
    setData() {
      const str = `linear-gradient(${this.degree}deg, ${this.color1}, ${this.color2})`
      this.setStyleVariableDirect({
        env: this.env,
        value: str,
        variable: this.variable
      })
    }
  },
  mounted() {
    let data = this.getStyleVariableValue(this.env, this.variable, 0)
    data = data.substring(data.indexOf("(") + 1, data.lastIndexOf(")"))
    data = data.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)
    this.degree = parseInt(data[0].replace("deg", ""))
    this.color1 = data[1].trim()
    this.color2 = data[2].trim()
  }
}
</script>

<style lang="scss" scoped>
.color-gradient {
  .color {
    margin: 8px 0;
    display: flex;
    align-items: center;

    .index {
      width: 5rem;
    }
  }

  .angle {
    display: flex;
    align-items: center;

    .description {
      width: 5rem;
    }

    div {
      display: flex;

      input[type="range"] {
        width: 7rem;
        margin-right: 1rem;
      }

      input[type="number"] {
        width: 5rem;
      }
    }
  }
}
</style>
