<template>
  <aside class="w-48 h-screen flex flex-col bg-indigo-700">
    <div class="mt-4 font-medium text-3xl tracking-widest text-white text-center mx-auto">OSM</div>
    <div class="flex-1 mt-16 flex flex-col justify-between items-between">
      <div class="flex flex-col">
        <router-link :to="{ name: 'Dashboard' }" active-class="active" class="sidebar-link">Dashboard</router-link>
        <router-link :to="{ name: 'Campaigns' }" active-class="active" class="sidebar-link">Campaigns</router-link>
        <router-link :to="{ name: 'Billing' }" active-class="active" class="sidebar-link">Plan & Billing</router-link>
      </div>
      <div class="px-4 py-2 mb-4 mx-2 rounded-md bg-gray-900 bg-opacity-50 text-white text-center flex justify-between items-center">
        <div class="font-medium">Pageviews:</div>
        <div class="font-medium">{{ user.used_quota }} / {{ user.plan.quota }}</div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "SidebarNav",
  computed: mapState("user", ["user"])
}
</script>

<style lang="scss" scoped>
.sidebar-link {
  @apply my-2 uppercase py-2 w-36 mx-auto rounded-md text-white text-center font-medium;

  &.active {
    @apply bg-opacity-25 bg-gray-900;
  }
}
</style>
