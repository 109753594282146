<template>
  <SettingsElement>
    <template #title>{{ title }}</template>
    <template #body>
      <Size :variables="variables" />
    </template>
  </SettingsElement>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import Size from "@/components/campaign-builder/appearance-changers/Size"

export default {
  name: "SettingsSize",
  components: { Size, SettingsElement },
  props: {
    variables: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: "Size"
    }
  }
}
</script>
