<template>
  <dashboard-layout>
    <h1>Test</h1>
  </dashboard-layout>
</template>
<script>
import DashboardLayout from "@/layout/DashboardLayout"

export default {
  name: "Dashboard",
  components: { DashboardLayout }
}
</script>

<style scoped></style>
