<template>
  <dropdown-select v-model="currentFont" @change="set">
    <option v-for="font in fontList" :key="font" :selected="font === currentFont" :value="font">{{ font }}</option>
  </dropdown-select>
</template>

<script>
import { fontList, getImportUrl } from "@/lib/utils/google-font"
import { mapGetters } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "FontFamily",
  components: { DropdownSelect },
  mixins: [appearanceChangerMixin],
  props: ["variable"],
  data() {
    return {
      fontList: fontList,
      currentFont: ""
    }
  },
  computed: {
    ...mapGetters("preview", ["getCurrentPage", "getIframeDocuments", "getPageSelector"])
  },
  methods: {
    set() {
      this.setStyleVariableValue({
        env: this.env,
        variable: this.variable,
        value: this.currentFont
      })
      const importUrl = this.getImportURL()
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const link = doc.querySelector(`${this.getPageSelector} link[rel=stylesheet]`)
        link.setAttribute("href", importUrl)
      })
    },
    getImportURL() {
      let fonts = []
      Object.keys(this.getStyleVariables[this.getCurrentPage].style).forEach((env) => {
        fonts.push(this.getStyleVariables[this.getCurrentPage].style[env][this.variable])
      })
      return getImportUrl(fonts)
    }
  },
  created() {
    this.currentFont = this.getStyleVariableValue(this.env, this.variable, 0)
  }
}
</script>
