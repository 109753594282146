import modalStyleVariables from "@/lib/marketing-widgets/modal/style-variables"

const img = "https://cdn-res.keymedia.com/cms/images/us/036/0223_637219445004061799.jpg"

const defaultComponentsNoImage = {
  components: {
    default: {
      title: '<span style="font-weight: 900;">Awesome Campaign!</span>',
      description: "This is an awesome campaign!",
      form: [
        {
          type: "email",
          name: "email",
          label: "E-Mail Address",
          placeholder: "Write Email Here",
          text: ""
        }
      ],
      buttons: [
        {
          type: "primary",
          text: '<p><span style="font-weight: 700;">Sign Up</span></p>'
        }
      ],
      notes: "<p>This is legend wait for it dary campaign!</p>",
      img: undefined
    }
  }
}
const defaultComponents = {
  components: {
    default: {
      title: '<span style="font-weight: 900;">Awesome Campaign!</span>',
      description: "This is an awesome campaign!",
      form: [
        {
          type: "email",
          name: "email",
          label: "E-Mail Address",
          placeholder: "Write Email Here",
          text: ""
        }
      ],
      buttons: [
        {
          type: "primary",
          text: '<p><span style="font-weight: 700;">Sign Up</span></p>'
        }
      ],
      notes: "<p>This is legend wait for it dary campaign!</p>",
      img
    }
  }
}

function getModalTemplates() {
  const modalStyle = modalStyleVariables()
  return [
    ...[
      {
        name: "Modal",
        positions: [
          {
            name: "Center",
            class: "center-center"
          }
        ],
        layouts: [
          {
            name: "Double Column Right Image",
            class: "double-column-right-image",
            style: modalStyle.doubleColumnRightImage,
            ...defaultComponents
          },
          {
            name: "Double Column Left Image",
            class: "double-column-left-image",
            style: modalStyle.doubleColumnLeftImage,
            ...defaultComponents
          },
          {
            name: "Single Column Top Image",
            class: "single-column-top-image",
            style: modalStyle.singleColumnTopImage,
            ...defaultComponents
          },
          {
            name: "Single Column Bottom Image",
            class: "single-column-bottom-image",
            style: modalStyle.singleColumnBottomImage,
            ...defaultComponents
          },
          {
            name: "Background Image",
            class: "background-image",
            style: modalStyle.backgroundImage,
            ...defaultComponents
          },
          {
            name: "No Image",
            class: "single-column-no-image",
            style: modalStyle.singleColumnNoImage,
            ...defaultComponentsNoImage
          }
        ]
      },
      {
        name: "Fullscreen",
        positions: [
          {
            name: "Full Screen",
            class: "full-screen"
          }
        ],
        layouts: [
          {
            name: "Double Column Right Image",
            class: "double-column-right-image",

            style: modalStyle.fullScreenDoubleColumnRightImage,
            ...defaultComponents
          },
          {
            name: "Double Column Left Image",
            class: "double-column-left-image",
            style: modalStyle.fullScreenDoubleColumnLeftImage,
            ...defaultComponents
          },
          {
            name: "Single Column Top Image",
            class: "single-column-top-image",
            style: modalStyle.fullScreenSingleColumnTopImage,
            ...defaultComponents
          },
          {
            name: "Single Column Bottom Image",
            class: "single-column-bottom-image",
            style: modalStyle.fullScreenSingleColumnBottomImage,
            ...defaultComponents
          },
          {
            name: "Background Image",
            class: "background-image",

            style: modalStyle.fullScreenBackgroundImage,
            ...defaultComponents
          },
          {
            name: "No Image",
            class: "single-column-no-image",
            style: modalStyle.fullScreenSingleColumnNoImage,
            ...defaultComponentsNoImage
          }
        ]
      },
      {
        name: "Slide In",
        positions: [
          {
            name: "Top Left",
            class: "top-left"
          },
          {
            name: "Top Right",
            class: "top-right"
          },
          {
            name: "Top Center",
            class: "top-center"
          },
          {
            name: "Bottom Left",
            class: "bottom-left"
          },

          {
            name: "Bottom Center",
            class: "bottom-center"
          },

          {
            name: "Bottom Right",
            class: "bottom-right"
          }
        ],
        layouts: [
          {
            name: "Double Column Right Image",
            class: "double-column-right-image",
            style: modalStyle.doubleColumnRightImage,
            ...defaultComponents
          },
          {
            name: "Double Column Left Image",
            class: "double-column-left-image",

            style: modalStyle.doubleColumnLeftImage,
            ...defaultComponents
          },
          {
            name: "Single Column Top Image",
            class: "single-column-top-image",

            style: modalStyle.singleColumnTopImage,
            ...defaultComponents
          },
          {
            name: "Single Column Bottom Image",
            class: "single-column-bottom-image",

            style: modalStyle.singleColumnBottomImage,
            ...defaultComponents
          },
          {
            name: "Background Image",
            class: "background-image",

            style: modalStyle.backgroundImage,
            ...defaultComponents
          },
          {
            name: "No Image",
            class: "single-column-no-image",

            style: modalStyle.singleColumnNoImage,
            ...defaultComponentsNoImage
          }
        ]
      },
      {
        name: "Panel",
        positions: [
          {
            name: "Bottom Left",
            class: "panel-left"
          },
          {
            name: "Bottom Right",
            class: "panel-right"
          }
        ],
        layouts: [
          {
            name: "Panel With Image",
            class: "panel-image",
            style: modalStyle.panelImage,
            ...defaultComponents
          },
          {
            name: "Panel No Image",
            class: "panel-no-image",
            style: modalStyle.panelNoImage,
            components: {
              default: {
                title: '<span style="font-weight: 900;">Awesome Campaign!</span>',
                description: "This is an awesome campaign!",
                form: [
                  {
                    type: "email",
                    name: "email",
                    label: "E-Mail Address",
                    placeholder: "Write Email Here",
                    text: ""
                  }
                ],
                buttons: [
                  {
                    type: "primary",
                    text: '<p><span style="font-weight: 700;">Sign Up</span></p>'
                  }
                ],
                notes: "<p>This is legend wait for it dary campaign!</p>"
              }
            }
          }
        ]
      }
    ]
  ]
}

export default getModalTemplates
